/**
 * A state slice.
 * Used to store IDE related state.
 */
export const ide = {
  bundlerUrl: null,
  androidPublicKey: null,
  iosPublicKey: null,
  isAndroidDefaultApp: false,
  isIosDefaultApp: false,
};
