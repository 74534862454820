import { faro } from "@grafana/faro-web-sdk";
import React from "react";
// import screenshot from "../../../../assets/images/screenshot.png";
import {
  setMessages,
  SCREENSHOT_CAPTURE_FAILURE,
} from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import { EmulatorHelperService } from "../../../../services";

export const Screenshot = () => {
  const [appetize, emulatorOptions, dispatch] = useAppContext(
    (state) => state.appetize,
    (state) => state.emulatorOptions
  );
  const { session, isConfiguringBundler } = appetize;
  const { os } = emulatorOptions.selected;

  const onScreenshotButtonClick = async () => {
    try {
      faro.api.pushEvent("click_screenshot_button");

      const { data: screenshotBuffer, mimeType } = await session.screenshot(
        "buffer"
      );
      downloadScreenshot(screenshotBuffer, mimeType);

      const screenshotDownloadedMessage = EmulatorHelperService.getMessage(
        "Screenshot downloaded. Please check the Downloads folder."
      );

      dispatch(setMessages([screenshotDownloadedMessage]));
      faro.api.pushLog(["Screenshot downloaded successfully."]);
    } catch (error) {
      console.log("Error in Screenshot: ", error);

      const screenshotCaptureFailureError = EmulatorHelperService.getMessage(
        SCREENSHOT_CAPTURE_FAILURE,
        true
      );

      dispatch(setMessages([screenshotCaptureFailureError]));
      faro.api.pushError(new Error(screenshotCaptureFailureError.message));
    }
  };

  const downloadScreenshot = (screenshotBuffer, mimeType) => {
    const element = document.createElement("a");

    element.href = URL.createObjectURL(
      new Blob([screenshotBuffer], { type: mimeType })
    );

    const date = new Date();
    const fileTimeSuffix = `${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;
    element.download = `${os}_screenshot_${fileTimeSuffix}`;

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <button
      className={`w-1/2 flex justify-center items-center text-sm ${
        session && !isConfiguringBundler
          ? "text-dropdownOption dark:text-secondaryDark bg-white dark:bg-backgroundPrimaryDark hover:font-medium"
          : "text-inactiveTextColour dark:text-disabledDark bg-toggleDisabledBackground dark:bg-backgroundSecondaryDark"
      }`}
      disabled={!session || isConfiguringBundler}
      onClick={onScreenshotButtonClick}
    >
      <span>Screenshot</span>
      {/* <img
        src={screenshot}
        title="Capture Screenshot"
        className="max-w-[24px]"
      /> */}
    </button>
  );
};

/**
 * Apply class -> "max-xl:hidden mr-2" to Screenshot text when SVG for Screenshot is available.
 * This class hides the text from XL and smaller devices.
 *
 * Commented out Screenshot icon as it's not available as of now.
 */
