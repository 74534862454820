import React from "react";
import { ReactComponent as BuilderIdeLogoB } from "../../../../assets/images/builder-ide-logo-b.svg";
import { ReactComponent as BuilderIdeLogoBDark } from "../../../../assets/images/builder-ide-logo-b-dark.svg";
import { ReactComponent as BuilderIdeLogoIde } from "../../../../assets/images/builder-ide-logo-ide.svg";
import { ReactComponent as BuilderIdeLogoIdeDark } from "../../../../assets/images/builder-ide-logo-ide-dark.svg";
import { ReactComponent as BuilderIdeLogoUilder } from "../../../../assets/images/builder-ide-logo-uilder.svg";
import { ReactComponent as BuilderIdeLogoUilderDark } from "../../../../assets/images/builder-ide-logo-uilder-dark.svg";
import { useAppContext } from "../../../../hooks";

export const BuilderIdeLogo = () => {
  const [ui] = useAppContext((state) => state.ui);
  const { isLightMode } = ui;

  const getLogo = () => {
    return isLightMode ? (
      <>
        <BuilderIdeLogoB />
        <BuilderIdeLogoUilder className="mr-2" />
        <BuilderIdeLogoIde />
      </>
    ) : (
      <>
        <BuilderIdeLogoBDark />
        <BuilderIdeLogoUilderDark className="mr-2" />
        <BuilderIdeLogoIdeDark />
      </>
    );
  };

  return (
    <div className="flex items-end px-6 py-6 box-border border-b border-solid border-ideLogoBottomBorder dark:border-borderDark">
      {getLogo()}
    </div>
  );
};
