/**
 * A state slice.
 * Used to determine if application's UI state such as theme mode, sidebar expand/collapse status, etc.
 */
export const ui = {
  isLightMode: true,
  isLeftSidebarCollapsed: false,
  isRightSidebarCollapsed: false,
  messages: [],
  selectedTab: "debug-tab",
  isGeneralInfoModalOpen: false,
  isDefaultAppModalOpen: false,
  isAutomatedStepsFailureModalOpen: false,
};
