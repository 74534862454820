import {
  SET_AVAILABLE_DEVICES,
  SET_DEVICE,
  SET_OS,
  SET_OS_VERSION,
  SET_EMULATOR_OPTIONS_LOADING,
  SET_EMULATOR_OPTIONS_ERROR,
  SET_SCALE,
  SET_ROTATION,
  SET_EMULATOR_MODE,
} from "../actions";

export const emulatorOptionsReducer = (state, { type, payload }) => {
  const updateSelectedKey = (key, value) => ({
    ...state,
    emulatorOptions: {
      ...state.emulatorOptions,
      selected: {
        ...state.emulatorOptions.selected,
        [key]: value,
      },
    },
  });

  switch (type) {
    case SET_AVAILABLE_DEVICES:
      return {
        ...state,
        emulatorOptions: {
          ...state.emulatorOptions,
          available: {
            ...state.emulatorOptions.available,
            devices: payload,
          },
        },
      };
    case SET_OS:
      return updateSelectedKey("os", payload);
    case SET_OS_VERSION:
      return updateSelectedKey("osVersion", payload);
    case SET_DEVICE:
      return updateSelectedKey("device", payload);
    case SET_SCALE:
      return updateSelectedKey("scale", payload);
    case SET_EMULATOR_OPTIONS_LOADING:
      return {
        ...state,
        emulatorOptions: {
          ...state.emulatorOptions,
          isLoading: payload,
        },
      };
    case SET_EMULATOR_OPTIONS_ERROR:
      return {
        ...state,
        emulatorOptions: {
          ...state.emulatorOptions,
          error: payload,
        },
      };
    case SET_ROTATION:
      return {
        ...state,
        emulatorOptions: {
          ...state.emulatorOptions,
          rotation: payload,
        },
      };
    case SET_EMULATOR_MODE:
      return {
        ...state,
        emulatorOptions: {
          ...state.emulatorOptions,
          isStaticMode: payload,
        },
      };
    default:
      return state;
  }
};
