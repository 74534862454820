import {
  SET_CLIENT_STATUS,
  SET_CLIENT_LOADING_STATUS,
  SET_PUBLIC_KEY_INVALID,
  SET_DEBUG_LOGS,
  CLEAR_DEBUG_LOGS,
  SET_METRO_BUNDLER_LOGS,
  CLEAR_METRO_BUNDLER_LOGS,
  SET_ANDROID_DEBUG_STATUS,
  SET_IOS_DEBUG_STATUS,
  SET_SESSION,
  SET_ROTATION_STATUS,
  SET_SESSION_STATUS,
  SET_BUNDLER_CONFIG_STATUS,
} from "../actions";

export const appetizeReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_CLIENT_STATUS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          isClientReady: payload,
        },
      };
    case SET_CLIENT_LOADING_STATUS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          isClientLoading: payload,
        },
      };
    case SET_PUBLIC_KEY_INVALID:
      return state.appetize.invalidPublicKeys.includes(payload)
        ? state
        : {
            ...state,
            appetize: {
              ...state.appetize,
              invalidPublicKeys: [...state.appetize.invalidPublicKeys, payload],
            },
          };
    case SET_DEBUG_LOGS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          appetizeLogs: {
            ...state.appetize.appetizeLogs,
            debugLogs: [...state.appetize.appetizeLogs.debugLogs, payload],
          },
        },
      };
    case CLEAR_DEBUG_LOGS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          appetizeLogs: {
            ...state.appetize.appetizeLogs,
            debugLogs: [],
          },
        },
      };
    case SET_METRO_BUNDLER_LOGS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          appetizeLogs: {
            ...state.appetize.appetizeLogs,
            metroBundlerLogs: [
              ...state.appetize.appetizeLogs.metroBundlerLogs,
              payload,
            ],
          },
        },
      };
    case CLEAR_METRO_BUNDLER_LOGS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          appetizeLogs: {
            ...state.appetize.appetizeLogs,
            metroBundlerLogs: [],
          },
        },
      };
    case SET_ANDROID_DEBUG_STATUS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          isAndroidDebugEnabled: payload,
        },
      };
    case SET_IOS_DEBUG_STATUS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          isIosDebugEnabled: payload,
        },
      };
    case SET_SESSION:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          session: payload,
        },
      };
    case SET_ROTATION_STATUS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          isRotating: payload,
        },
      };
    case SET_SESSION_STATUS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          isSessionLoading: payload,
        },
      };
    case SET_BUNDLER_CONFIG_STATUS:
      return {
        ...state,
        appetize: {
          ...state.appetize,
          isConfiguringBundler: payload,
        },
      };
    default:
      return state;
  }
};
