import React from "react";
import {
  Appetize,
  MidPaneBackground,
  MidPaneTop,
  DefaultAppModal,
  MidPaneMessages,
} from "./components";
import "./mid-pane.scss";

/**
 * Mid Pane - the window between left and right sidebars.
 * To be implemented.
 */
export const MidPane = () => {
  return (
    <div className={`w-full h-full flex-grow flex flex-col relative`}>
      <MidPaneTop />

      <MidPaneMessages />

      <Appetize />

      <MidPaneBackground />

      <DefaultAppModal />
    </div>
  );
};
