import { faro } from "@grafana/faro-web-sdk";
import React, { useEffect } from "react";
import {
  setAvailableDevices,
  setOs,
  setOsVersion,
  setDevice,
  setEmulatorOptionsLoading,
  setEmulatorOptionsError,
} from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import {
  AppetizeApiService,
  DataTransformerService,
} from "../../../../services";
import { Option, OrientationOption } from "./components";
import { APPETIZE_FETCH_DEVICE_ERROR } from "../../../../global-state";

const { getOsOptionsFromAvailableDevices, getOsVersionOptionsForDevice } =
  DataTransformerService;

/**
 * Renders Emulator options in the Left Sidebar.
 */
export const EmulatorOptions = () => {
  const [emulatorOptions, appetize, ide, dispatch] = useAppContext(
    (state) => state.emulatorOptions,
    (state) => state.appetize,
    (state) => state.ide
  );
  const { available, selected, isLoading, error } = emulatorOptions;
  const {
    isClientReady,
    invalidPublicKeys,
    isClientLoading,
    isConfiguringBundler,
    session,
  } = appetize;
  const { androidPublicKey, iosPublicKey } = ide;

  useEffect(() => {
    (async () => {
      try {
        dispatch(setEmulatorOptionsLoading(true));
        const availableDevices = await AppetizeApiService.getAvailableDevices();
        dispatch(setAvailableDevices(availableDevices));
      } catch (error) {
        dispatch(setEmulatorOptionsError(APPETIZE_FETCH_DEVICE_ERROR));
      }
      dispatch(setEmulatorOptionsLoading(false));
    })();
  }, []);

  /**
   * Commenting out for now.
   * Only supporting latest version for now.
   * Bring this back when multiple app versions are supported.
   *
   * const appVersionOptions = available.appVersions.map((appVersion) => ({
   *  label: appVersion,
   *  value: appVersion,
   * }));
   */

  const osOptions = getOsOptionsFromAvailableDevices(
    available.devices,
    !!androidPublicKey,
    !!iosPublicKey
  );
  const selectedOsOption =
    osOptions.find((option) => option.value === selected.os) || null;
  const onOsChange = (selectedOption) => {
    if (selectedOption.value !== selected.os) {
      dispatch(setOs(selectedOption.value));
      // dispatch(setDevice(null));
      // dispatch(setOsVersion(null));

      faro.api.pushEvent("select_os", { selectedOs: selectedOption.value });
    }
  };

  const deviceOptions = DataTransformerService.getDeviceOptionsForOs(
    available.devices,
    selected.os
  );
  const selectedDeviceOption =
    deviceOptions.find((option) => option.value === selected.device) || null;
  const onDeviceChange = (selectedOption) => {
    if (selectedOption.value !== selected.device) {
      dispatch(setDevice(selectedOption.value));
      // dispatch(setOsVersion(null));

      faro.api.pushEvent("select_device", {
        selectedDevice: selectedOption.value,
      });
    }
  };

  const osVersionOptions = getOsVersionOptionsForDevice(
    available.devices,
    selected.os,
    selected.device
  );
  const selectedOsVersionOption =
    osVersionOptions.find((option) => option.value === selected.osVersion) ||
    null;
  const onOsVersionChange = (selectedOption) => {
    if (selectedOption.value !== selected.osVersion) {
      dispatch(setOsVersion(selectedOption.value));

      faro.api.pushEvent("select_os_version", {
        selectedOsVersion: selectedOption.value,
      });
    }
  };

  return (
    <div className="px-6 py-6">
      <h1 className="mb-4 text-base font-medium dark:text-primaryDark">
        Select parameters
      </h1>

      {/**
       * Commenting out App Version dropdown for now.
       * Current implementation only supports Latest app version.
       * Bring this back when multiple app versions are supported.
       *
       * <Option
       *  id="option-app-version"
       *  title="app version"
       *  options={appVersionOptions}
       *  selectedOption={appVersionOptions.find(
       *   (option) => option.value === selected.appVersion,
       *  )}
       *  onChange={() => {}}
       *  >
       */}

      <Option
        id="option-operating-system"
        title="operating system"
        options={osOptions}
        selectedOption={selectedOsOption}
        onChange={onOsChange}
        isDisabled={
          isClientLoading ||
          (!isClientReady && invalidPublicKeys.length === 0) ||
          isLoading ||
          error ||
          (isConfiguringBundler && !session)
        }
      />
      <Option
        id="option-device"
        title="device"
        options={deviceOptions}
        selectedOption={selectedDeviceOption}
        onChange={onDeviceChange}
        isDisabled={
          isClientLoading ||
          !isClientReady ||
          isLoading ||
          error ||
          !selected.os ||
          (isConfiguringBundler && !session)
        }
      />
      <Option
        id="option-os-version"
        title="os version"
        options={osVersionOptions}
        selectedOption={selectedOsVersionOption}
        onChange={onOsVersionChange}
        isDisabled={
          isClientLoading ||
          !isClientReady ||
          isLoading ||
          error ||
          !selected.os ||
          !selected.device ||
          (isConfiguringBundler && !session)
        }
      />
      <OrientationOption />
    </div>
  );
};
