import {
  SET_THEME_ACTION,
  SET_LEFT_SIDEBAR_STATUS,
  SET_RIGHT_SIDEBAR_STATUS,
  SET_AVAILABLE_DEVICES,
  SET_DEVICE,
  SET_OS,
  SET_OS_VERSION,
  SET_EMULATOR_OPTIONS_LOADING,
  SET_EMULATOR_OPTIONS_ERROR,
  SET_CLIENT_STATUS,
  SET_CLIENT_LOADING_STATUS,
  SET_PUBLIC_KEY_INVALID,
  SET_DEBUG_LOGS,
  SET_METRO_BUNDLER_LOGS,
  CLEAR_DEBUG_LOGS,
  CLEAR_METRO_BUNDLER_LOGS,
  SET_ANDROID_DEBUG_STATUS,
  SET_IOS_DEBUG_STATUS,
  SET_SESSION,
  SET_SCALE,
  SET_IDE_DETAILS,
  SET_MESSAGES,
  REMOVE_MESSAGES,
  SET_ROTATION,
  SET_ROTATION_STATUS,
  SET_EMULATOR_MODE,
  SET_SESSION_STATUS,
  SET_BUNDLER_CONFIG_STATUS,
  SET_SELECTED_TAB,
  SET_GENERAL_INFO_MODAL_STATUS,
  SET_DEFAULT_APP_MODAL_STATUS,
  SET_AUTOMATED_STEPS_FAILURE_MODAL_STATUS,
} from "../actions";
import { appetizeReducer } from "./appetize-reducer";
import { emulatorOptionsReducer } from "./emulator-options-reducer";
import { ideReducer } from "./ide-reducer";
import { uiReducer } from "./ui-reducer";

/**
 * Reducer function to handle App/Global state updates.
 * Takes in an action of specific type (second param) and updates state as per the action type and payload.
 */
export const appReducer = (state, action) => {
  switch (action.type) {
    case SET_THEME_ACTION:
    case SET_LEFT_SIDEBAR_STATUS:
    case SET_RIGHT_SIDEBAR_STATUS:
    case SET_MESSAGES:
    case REMOVE_MESSAGES:
    case SET_SELECTED_TAB:
    case SET_GENERAL_INFO_MODAL_STATUS:
    case SET_DEFAULT_APP_MODAL_STATUS:
    case SET_AUTOMATED_STEPS_FAILURE_MODAL_STATUS:
      return uiReducer(state, action);
    case SET_AVAILABLE_DEVICES:
    case SET_DEVICE:
    case SET_OS:
    case SET_OS_VERSION:
    case SET_EMULATOR_OPTIONS_LOADING:
    case SET_EMULATOR_OPTIONS_ERROR:
    case SET_SCALE:
    case SET_ROTATION:
    case SET_EMULATOR_MODE:
      return emulatorOptionsReducer(state, action);
    case SET_CLIENT_STATUS:
    case SET_CLIENT_LOADING_STATUS:
    case SET_PUBLIC_KEY_INVALID:
    case SET_DEBUG_LOGS:
    case CLEAR_DEBUG_LOGS:
    case SET_METRO_BUNDLER_LOGS:
    case CLEAR_METRO_BUNDLER_LOGS:
    case SET_ANDROID_DEBUG_STATUS:
    case SET_IOS_DEBUG_STATUS:
    case SET_SESSION:
    case SET_ROTATION_STATUS:
    case SET_SESSION_STATUS:
    case SET_BUNDLER_CONFIG_STATUS:
      return appetizeReducer(state, action);
    case SET_IDE_DETAILS:
      return ideReducer(state, action);
    default:
      return state;
  }
};
