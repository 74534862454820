import { DataTransformerService } from "../DataTransformerService";

export class AdminApiService {
  static getEnvironment() {
    return window.location.host.includes(".prod.") ? "prod" : "other";
  }

  static getAdminUrl() {
    const env = this.getEnvironment();
    return env === "prod"
      ? "https://admin.bxide-svc.prod.centralindia.az.svc.builder.ai"
      : "https://admin.bxide-svc.stage.eastus.az.svc.builder.ai";
  }

  static async getDefaultAppStatus(instanceName, signal) {
    const adminUrl = this.getAdminUrl();

    const response = await fetch(
      `${adminUrl}/api/v2/ides/default_emu_app/${instanceName}`,
      { signal },
    );
    const result = await response.json();

    return !result.error
      ? DataTransformerService.getDefaultAppStatus(result)
      : { error: result.error.message };
  }
}
