import React from "react";
import { Screenshot } from "../Screenshot";
import { Shake } from "../Shake";

export const Utilities = () => {
  return (
    <div className="w-full px-6 py-6">
      <div
        className={`w-full min-w-[94px] h-[38px] flex overflow-hidden rounded-[3px] border border-solid border-dropdownBorder dark:border-borderDark`}
      >
        <Shake />
        <Screenshot />
      </div>
    </div>
  );
};
