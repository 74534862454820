import React from "react";
import "./logs.scss";

export const Log = ({
  logId,
  appearanceCount,
  log,
  query,
  scrollIntoViewClass,
  onLogHighlight,
}) => {
  const getHighlightedLog = () => {
    const logTokens = log.split(query);

    return (
      <p className="log overflow-hidden">
        {logTokens.map((logToken, index) => {
          const identifier = `highlighted-log-${logId}-${index}`;

          return (
            <span key={identifier}>
              {!!logToken && <span>{logToken}</span>}
              {index < logTokens.length - 1 && (
                <mark
                  id={identifier}
                  className="bg-ideLogoLight dark:bg-ideLogoDark"
                  ref={onLogHighlight}
                >
                  {query}
                </mark>
              )}
            </span>
          );
        })}
      </p>
    );
  };

  const getUnhighlightedLog = () => (
    <p
      className={`${
        logId === "debugging-not-enabled" &&
        "text-inactiveTextColour dark:text-disabledDark font-semibold dark:font-normal tracking-wide dark:tracking-normal"
      } overflow-hidden log`}
    >
      {log}
    </p>
  );

  return (
    <div
      className={`w-full pb-3 flex justify-start items-baseline gap-x-2 ${scrollIntoViewClass}`}
    >
      <div className="flex flex-col justify-start items-center">
        <p className="flex justify-center items-start text-lg font-bold text-dropdownBorder dark:text-disabledDark">
          &gt;
        </p>
        {!!appearanceCount && (
          <p
            className={`flex justify-center items-center min-w-[1.25rem] min-h-[1.25rem] max-w-[1.25rem] max-h-[1.25rem] bg-dropdownBorder dark:bg-disabledDark rounded-full text-xs font-semibold text-dropdownOption dark:text-secondaryDark`}
          >
            {appearanceCount}
          </p>
        )}
      </div>
      {query && log.includes(query)
        ? getHighlightedLog()
        : getUnhighlightedLog()}
    </div>
  );
};
