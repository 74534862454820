export const NO_PUBLIC_KEY_ERROR =
  "IDE_EM_400A: No Public Key found for Android or iOS!";
export const NO_BUNDLER_URL_ERROR = "IDE_EM_400B: No bundler URL found!";
export const APPETIZE_APP_NOT_FOUND_ERROR =
  "IDE_EM_404: {OS_REPLACE} App Not Found";
export const APPETIZE_FETCH_DEVICE_ERROR =
  "IDE_EM_424: Failed to fetch available devices!";
export const BUNDLER_CONNECTION_ERROR =
  "IDE_EM_412A: Failed to connect to Metro Bundler!";
export const BUNDLER_AUTOMATED_STEPS_FAILURE_ERROR =
  "IDE_EM_503: Warning, cannot automate bundler connection.";
export const DNS_BUNDLER_ERROR = "IDE_EM_412B: Metro Bundler is not running!";

// APPETIZE_DEBUG_AUTH_ERROR is an Appetize-specific error code which should not be changed
export const APPETIZE_DEBUG_AUTH_ERROR =
  "You must be logged in to an authorised account in order to debug this app";

export const DEVICE_SHAKE_FAILURE = "IDE_EM_S500: Failed to shake the device!";
export const SCREENSHOT_CAPTURE_FAILURE =
  "IDE_EM_C500: Failed to capture screenshot!";
export const DEFAULT_APP_CHECK_FAILURE =
  "IDE_EM_500: Warning, unable to check for Default App location (this warning does not affect the emulator)";
