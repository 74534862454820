import React from "react";
import { useAppContext } from "../../../../hooks";
import "./mid-pane-background.scss";

export const MidPaneBackground = () => {
  const [ui] = useAppContext((state) => state.ui);
  const { isLightMode, isLeftSidebarCollapsed, isRightSidebarCollapsed } = ui;

  return (
    <div
      className={`absolute top-0 bottom-0 left-0 right-0 -z-20 ${
        isLightMode ? "mid-pane-background-light" : "mid-pane-background-dark"
      }`}
    >
      <div
        className={`h-full mx-auto my-0 mid-pane-dotted-background ${
          isLeftSidebarCollapsed || isRightSidebarCollapsed ? "w-3/5" : "w-full"
        }`}
      >
        <div className="w-full h-1/5"></div>
        <div className="w-full h-1/5"></div>
        <div className="w-full h-1/5"></div>
        <div className="w-full h-1/5"></div>
        <div className="w-full h-1/5"></div>
      </div>
    </div>
  );
};
