import React from "react";
import { useAppContext } from "../../../../hooks";

export const SearchCount = ({
  logsByDuplicacy,
  highlightedLogElements,
  activeHighlightedLog,
}) => {
  const [ui] = useAppContext((state) => state.ui);
  const { selectedTab } = ui;

  const isSearchDisabled =
    !logsByDuplicacy ||
    (selectedTab === "debug-tab" && !logsByDuplicacy.debugLogs.length) ||
    (selectedTab === "metro-bundler-tab" &&
      !logsByDuplicacy.metroBundlerLogs.length);

  const getActiveHighlightedLogIndex = () =>
    highlightedLogElements.findIndex(
      (highlightedLogElement) => highlightedLogElement === activeHighlightedLog,
    );

  return (
    <span
      className={`text-l ${
        !isSearchDisabled
          ? "text-dropdownOption dark:text-secondaryDark"
          : "text-inactiveTextColour dark:text-disabledDark"
      }`}
    >
      {getActiveHighlightedLogIndex() + 1} / {highlightedLogElements.length}
    </span>
  );
};
