import { faro } from "@grafana/faro-web-sdk";
import React from "react";
import {
  setMessages,
  removeMessages,
  setEmulatorMode,
  // setSelectedTab,
  clearDebugLogs,
  clearMetroBundlerLogs,
  NO_BUNDLER_URL_ERROR,
} from "../../../../../../global-state";
import { useAppContext } from "../../../../../../hooks";
import { Toggle } from "../../../../../shared";
import { BUNDLER_CONNECTION_ERROR } from "../../../../../../global-state";
import { EmulatorHelperService } from "../../../../../../services";

export const EmulatorModeToggle = () => {
  const [appetize, emulatorOptions, ide, ui, dispatch] = useAppContext(
    (state) => state.appetize,
    (state) => state.emulatorOptions,
    (state) => state.ide,
    (state) => state.ui
  );
  const { isSessionLoading } = appetize;
  const { isStaticMode } = emulatorOptions;
  const { bundlerUrl } = ide;
  const { messages } = ui;

  const onEmulatorModeChange = async (isStaticModeSelected) => {
    if (
      !isStaticModeSelected &&
      !bundlerUrl &&
      !messages.find(({ message }) => message === NO_BUNDLER_URL_ERROR)
    ) {
      const noBundlerUrlError = EmulatorHelperService.getMessage(
        NO_BUNDLER_URL_ERROR,
        true
      );

      dispatch(setMessages([noBundlerUrlError]));
      faro.api.pushError(new Error(noBundlerUrlError.message));

      return;
    }

    const bundlerConnectionError = messages.find(
      ({ message }) => message === BUNDLER_CONNECTION_ERROR
    );
    !isStaticModeSelected &&
      bundlerConnectionError &&
      dispatch(removeMessages([bundlerConnectionError]));

    if (isStaticModeSelected !== isStaticMode) {
      dispatch(setEmulatorMode(isStaticModeSelected));

      if (isStaticModeSelected) {
        dispatch(clearDebugLogs());
        dispatch(clearMetroBundlerLogs());
      }

      /**
       * Metro Bundler and Other tabs have been disabled for V1.
       * Commenting tab selection code out as only Debug tab is available.
       */
      // isStaticModeSelected && dispatch(setSelectedTab("debug-tab"));

      faro.api.pushEvent(
        `click_${isStaticModeSelected ? "static" : "local"}_button`
      );
    }
  };

  const getButtonTitle = (title) => (
    <p
      className={`px-2 uppercase text-sm ${
        (title === "static" &&
          !isStaticMode &&
          isSessionLoading &&
          "font-medium") ||
        (title === "local" &&
          isStaticMode &&
          isSessionLoading &&
          "font-medium") ||
        "font-semibold"
      }`}
    >
      {title}
    </p>
  );

  return (
    <Toggle
      leftButtonContent={getButtonTitle("static")}
      rightButtonContent={getButtonTitle("local")}
      isLeftSelected={isStaticMode}
      isLeftButtonDisabled={!isStaticMode && isSessionLoading}
      isRightButtonDisabled={isStaticMode && isSessionLoading}
      onChange={onEmulatorModeChange}
    />
  );
};
