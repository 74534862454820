import React, { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../hooks";
import { DataTransformerService } from "../../services";
import {
  Logs,
  DownloadLogs,
  ClearLogs,
  SearchLogs,
  SearchCount,
} from "./components";
import "./right-sidebar.scss";

const { getCategorisedLogs } = DataTransformerService;

export const RightSidebar = () => {
  const [appetize] = useAppContext((state) => state.appetize);
  const { appetizeLogs } = appetize;

  const [logsByDuplicacy, setLogsByDuplicacy] = useState(null);
  const [query, setQuery] = useState("");
  const [highlightedLogElements, setHighlightedLogElements] = useState([]);
  const [activeHighlightedLog, setActiveHighlightedLog] = useState(null);

  useEffect(() => {
    setLogsByDuplicacy(getCategorisedLogs(appetizeLogs));
  }, [appetizeLogs]);

  const onQueryChange = (query) => setQuery(query);

  const onLogHighlight = useCallback(
    (highlightedLogElement) =>
      highlightedLogElement &&
      setHighlightedLogElements((highlightedLogElements) => [
        ...highlightedLogElements.filter(
          (element) =>
            element !== highlightedLogElement && element.textContent === query,
        ),
        highlightedLogElement,
      ]),
    [query],
  );

  const onActiveHighlightedLogChange = (activeHighlightedLog) => {
    setActiveHighlightedLog(activeHighlightedLog);
  };

  const onUnmatchedQuery = useCallback(() => {
    setHighlightedLogElements([]);
  }, []);

  return (
    <div className="w-full h-full px-6 py-6 flex flex-col overflow-hidden">
      <Logs
        logsByDuplicacy={logsByDuplicacy}
        query={query}
        onLogHighlight={onLogHighlight}
        onUnmatchedQuery={onUnmatchedQuery}
      />

      <div className="w-full flex flex-col justify-end items-end">
        <SearchLogs
          logsByDuplicacy={logsByDuplicacy}
          highlightedLogElements={highlightedLogElements}
          onQueryChange={onQueryChange}
          onActiveHighlightedLogChange={onActiveHighlightedLogChange}
        />

        <div className="w-full mt-4 flex justify-between items-center">
          <SearchCount
            logsByDuplicacy={logsByDuplicacy}
            highlightedLogElements={highlightedLogElements}
            activeHighlightedLog={activeHighlightedLog}
          />

          <div className="flex gap-x-2">
            <DownloadLogs logsByDuplicacy={logsByDuplicacy} />
            <ClearLogs logsByDuplicacy={logsByDuplicacy} />
          </div>
        </div>
      </div>
    </div>
  );
};
