import React from "react";
import Select from "react-select";
import "./dropdown.scss";
import { useAppContext } from "../../../hooks";

export const Dropdown = ({
  id,
  title,
  options,
  selectedOption,
  onChange,
  isDisabled,
}) => {
  const [ui] = useAppContext((state) => state.ui);
  const { isLightMode } = ui;

  return (
    <Select
      id={id}
      className={"selectbox"}
      placeholder={`Select ${title}`}
      options={options}
      value={selectedOption}
      onChange={onChange}
      isDisabled={isDisabled}
      components={{ IndicatorSeparator: false }}
      classNamePrefix="emulator-dropdown"
      unstyled={true}
      classNames={{
        control: (state) =>
          `px-3 py-1 text-sm border border-solid border-dropdownBorder dark:border-borderDark rounded-[3px] overflow-hidden ${
            !state.isDisabled
              ? "text-dropdownOption dark:text-secondaryDark cursor-pointer"
              : "text-inactiveTextColour dark:text-disabledDark bg-toggleDisabledBackground dark:bg-backgroundSecondaryDark"
          }`,
        menu: () =>
          `top-2 bg-white dark:bg-backgroundPrimaryDark border border-solid border-dropdownBorder dark:border-borderDark rounded-[3px] overflow-hidden`,
        menuList: () =>
          `text-sm ${isLightMode ? "light-scrollbar" : "dark-scrollbar"}`,
        option: (state) =>
          `px-3 py-2 text-dropdownOption dark:text-primaryDark ${
            state.isSelected &&
            "bg-dropdownSelectedBackground dark:bg-backgroundSecondaryDark"
          } border-b border-solid border-dropdownBorder dark:border-borderDark`,
      }}
    />
  );
};
