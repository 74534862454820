/**
 * Action type to update theme of the application.
 * Used by the reducer to update theme state slice.
 */
export const SET_THEME_ACTION = "SET_THEME_ACTION";

/**
 * A function to create action for updating theme state slice.
 * Receives a boolean indicating the theme mode.
 * Sets up the action payload to received theme mode.
 */
export const setTheme = (isLightMode) => ({
  type: SET_THEME_ACTION,
  payload: isLightMode,
});

export const SET_LEFT_SIDEBAR_STATUS = "SET_LEFT_SIDEBAR_STATUS";
export const setLeftSidebarStatus = (isCollapsed) => ({
  type: SET_LEFT_SIDEBAR_STATUS,
  payload: isCollapsed,
});

export const SET_RIGHT_SIDEBAR_STATUS = "SET_RIGHT_SIDEBAR_STATUS";
export const setRightSidebarStatus = (isCollapsed) => ({
  type: SET_RIGHT_SIDEBAR_STATUS,
  payload: isCollapsed,
});

export const SET_MESSAGES = "SET_MESSAGES";
export const setMessages = (messages) => ({
  type: SET_MESSAGES,
  payload: messages,
});

export const REMOVE_MESSAGES = "REMOVE_MESSAGES";
export const removeMessages = (messages) => ({
  type: REMOVE_MESSAGES,
  payload: messages,
});

export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const setSelectedTab = (selectedTabId) => ({
  type: SET_SELECTED_TAB,
  payload: selectedTabId,
});

export const SET_GENERAL_INFO_MODAL_STATUS = "SET_GENERAL_INFO_MODAL_STATUS";
export const setGeneralInfoModalStatus = (isGeneralInfoModalOpen) => ({
  type: SET_GENERAL_INFO_MODAL_STATUS,
  payload: isGeneralInfoModalOpen,
});

export const SET_DEFAULT_APP_MODAL_STATUS = "SET_DEFAULT_APP_MODAL_STATUS";
export const setDefaultAppModalStatus = (isDefaultAppModalOpen) => ({
  type: SET_DEFAULT_APP_MODAL_STATUS,
  payload: isDefaultAppModalOpen,
});

export const SET_AUTOMATED_STEPS_FAILURE_MODAL_STATUS =
  "SET_AUTOMATED_STEPS_FAILURE_MODAL_STATUS";
export const setAutomatedStepsFailureModalStatus = (
  isAutomatedStepsFailureModalOpen
) => ({
  type: SET_AUTOMATED_STEPS_FAILURE_MODAL_STATUS,
  payload: isAutomatedStepsFailureModalOpen,
});
