/**
 * Defines all action types and action creators regarding appetize state.
 */

export const SET_CLIENT_STATUS = "SET_CLIENT_STATUS";
export const setClientStatus = (isClientReady) => ({
  type: SET_CLIENT_STATUS,
  payload: isClientReady,
});

export const SET_CLIENT_LOADING_STATUS = "SET_CLIENT_LOADING_STATUS";
export const setClientLoading = (isClientLoading) => ({
  type: SET_CLIENT_LOADING_STATUS,
  payload: isClientLoading,
});

export const SET_PUBLIC_KEY_INVALID = "SET_PUBLIC_KEY_INVALID";
export const setPublicKeyInvalid = (invalidPublicKey) => ({
  type: SET_PUBLIC_KEY_INVALID,
  payload: invalidPublicKey,
});

export const SET_DEBUG_LOGS = "SET_DEBUG_LOGS";
export const setDebugLogs = (log) => ({
  type: SET_DEBUG_LOGS,
  payload: log,
});

export const SET_METRO_BUNDLER_LOGS = "SET_METRO_BUNDLER_LOGS";
export const setMetroBundlerLogs = (log) => ({
  type: SET_METRO_BUNDLER_LOGS,
  payload: log,
});

export const CLEAR_DEBUG_LOGS = "CLEAR_DEBUG_LOGS";
export const clearDebugLogs = () => ({
  type: CLEAR_DEBUG_LOGS,
});

export const CLEAR_METRO_BUNDLER_LOGS = "CLEAR_METRO_BUNDLER_LOGS";
export const clearMetroBundlerLogs = () => ({
  type: CLEAR_METRO_BUNDLER_LOGS,
});

export const SET_ANDROID_DEBUG_STATUS = "SET_ANDROID_DEBUG_STATUS";
export const setAndroidDebugStatus = (isDebugEnabled) => ({
  type: SET_ANDROID_DEBUG_STATUS,
  payload: isDebugEnabled,
});

export const SET_IOS_DEBUG_STATUS = "SET_IOS_DEBUG_STATUS";
export const setIosDebugStatus = (isDebugEnabled) => ({
  type: SET_IOS_DEBUG_STATUS,
  payload: isDebugEnabled,
});

export const SET_SESSION = "SET_SESSION";
export const setSession = (session) => ({
  type: SET_SESSION,
  payload: session,
});

export const SET_ROTATION_STATUS = "SET_ROTATION_STATUS";
export const setRotationStatus = (isRotating) => ({
  type: SET_ROTATION_STATUS,
  payload: isRotating,
});

export const SET_SESSION_STATUS = "SET_SESSION_STATUS";
export const setSessionStatus = (isSessionLoading) => ({
  type: SET_SESSION_STATUS,
  payload: isSessionLoading,
});

export const SET_BUNDLER_CONFIG_STATUS = "SET_BUNDLER_CONFIG_STATUS";
export const setBundlerConfigStatus = (isConfiguringBundler) => ({
  type: SET_BUNDLER_CONFIG_STATUS,
  payload: isConfiguringBundler,
});
