import React from "react";
import { Modal } from "../../../shared";
import { setGeneralInfoModalStatus } from "../../../../global-state";
import { useAppContext } from "../../../../hooks";

export const GeneralInfoLink = () => {
  const [ui, dispatch] = useAppContext((state) => state.ui);
  const { isGeneralInfoModalOpen } = ui;

  const toggleGeneralInfoModal = () => {
    dispatch(setGeneralInfoModalStatus(!isGeneralInfoModalOpen));
  };

  const getGeneralInfoModalContent = () => (
    <div className="text-sm text-slate-900 dark:text-secondaryDark">
      <p>
        <b>STATIC mode:</b> The latest APP/APK built from your branch&apos;s
        pipeline.
        <br />
        <b>LOCAL mode</b>: Your locally running app (from `npx react-native
        start --port 9000`)
      </p>
      <br />
      <p>
        <b>Debug logs:</b> App logs reported when running the app (works for
        both STATIC and LOCAL)
        {/* <br /> */}
        {/* <b>Metro Bundler Logs:</b> Your local Metro Bundler logs when running */}
        {/* `npx react-native start --port 9000` (only LOCAL mode) */}
      </p>
      <br />
      <p>
        <b>DEFAULT APP SETTINGS</b> <br />
        Use these settings if you don&apos;t need a special OS/Android version -
        make sure you develop a working app for the following:
        <br />
        <b>iOS</b> Device: iPhone 8, OS version: 13.7
        <br />
        <b>Android</b> Device: Pixel 4, OS Version: 11.0
      </p>
    </div>
  );

  return (
    <div
      className="px-6 pt-6 flex items-center gap-x-1.5 cursor-pointer"
      onClick={toggleGeneralInfoModal}
    >
      <span className="flex justify-center items-center min-w-[1.25rem] min-h-[1.25rem] max-w-[1.25rem] max-h-[1.25rem] bg-ideLogoLight dark:bg-ideLogoDark rounded-full text-sm font-semibold text-white dark:text-backgroundPrimaryDark">
        ?
      </span>
      <span className="text-parameterTitleColour dark:text-secondaryDark text-sm font-medium hover:underline">
        General Information
      </span>

      <Modal
        isOpen={isGeneralInfoModalOpen}
        title="General Information"
        content={getGeneralInfoModalContent()}
        onClose={toggleGeneralInfoModal}
      />
    </div>
  );
};
