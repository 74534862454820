import React from "react";
import { useAppContext } from "../../../hooks";
import "./tabs.scss";

export const Tabs = ({ tabsData, selectedTabId, onChange }) => {
  const [ui] = useAppContext((state) => state.ui);
  const { isLightMode } = ui;

  const onTabClick = (clickedTabId) => {
    const currentSelectedTab = tabsData.find(
      (tabData) => tabData.id === selectedTabId,
    );

    if (!currentSelectedTab) {
      onChange(clickedTabId);
      return;
    }

    currentSelectedTab.id !== clickedTabId && onChange(clickedTabId);
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex">
        {tabsData.map((tabData) => (
          <button
            className={`${
              tabsData.length > 1 ? "flex-grow" : "basis-1/3 cursor-default"
            } p-2 overflow-hidden whitespace-nowrap text-ellipsis rounded-t-lg text-sm font-medium transition-colors duration-200 ease-linear ${
              tabData.id === selectedTabId
                ? "bg-logsBackgroundColour dark:bg-backgroundSecondaryDark dark:text-primaryDark"
                : "text-inactiveTextColour dark:text-secondaryDark"
            }`}
            key={tabData.id}
            onClick={() => onTabClick(tabData.id)}
          >
            {tabData.title}
          </button>
        ))}
      </div>

      <div className="flex-grow bg-logsBackgroundColour dark:bg-backgroundSecondaryDark rounded-b-lg overflow-hidden">
        <div
          className={`tab-content ${
            isLightMode ? "light-scrollbar" : "dark-scrollbar"
          } w-full h-full px-4 py-3 overflow-y-auto overflow-x-auto text-xs text-logsTextColour dark:text-primaryDark font-roboto`}
        >
          {tabsData.find((tabData) => tabData.id === selectedTabId)?.content ||
            null}
        </div>
      </div>
    </div>
  );
};
