export class DataTransformerService {
  static getOsOptionsFromAvailableDevices(
    availableDevices,
    shouldShowAndroid,
    shouldShowIos,
  ) {
    let osOptions = Object.keys(availableDevices).map((os) => ({
      label: os === "android" ? "Android" : "iOS",
      value: os,
    }));

    if (!shouldShowAndroid)
      osOptions = osOptions.filter((option) => option.value !== "android");

    if (!shouldShowIos)
      osOptions = osOptions.filter((option) => option.value !== "ios");

    return osOptions;
  }

  static getDeviceLabel(device) {
    // Format device labels programmatically
    if (device.includes("iphone")) {
      device = device.replace("iphone", "iPhone");
    } else if (device.includes("ipad")) {
      device = device.replace("ipad", "iPad");
    } else {
      device = device.charAt(0).toUpperCase() + device.slice(1);
    }

    // Match non-digits, then add a space
    const re = /[^0-9](?=[0-9])/g;
    device = device.replace(re, "$& ");

    const wordsToCapitalise = ["plus", "pro", "max", "generation", "inch"];
    for (let i = 0; i < wordsToCapitalise.length; i++) {
      const capitalisedWord =
        wordsToCapitalise[i].charAt(0).toUpperCase() +
        wordsToCapitalise[i].slice(1);

      device = device.replaceAll(wordsToCapitalise[i], ` ${capitalisedWord}`);
    }

    return device;
  }

  static getDeviceOptionsForOs(availableDevices, os) {
    return os
      ? Object.keys(availableDevices[os]).map((device) => ({
          label: this.getDeviceLabel(device),
          value: device,
        }))
      : [];
  }

  static getOsVersionOptionsForDevice(availableDevices, os, device) {
    return availableDevices[os] && availableDevices[os][device] && os && device
      ? availableDevices[os][device].map((osVersion) => ({
          label: osVersion,
          value: osVersion,
        }))
      : [];
  }

  static getCategorisedLogs(appetizeLogs) {
    const debugLogsMap = new Map();
    const metroBundlerLogsMap = new Map();

    appetizeLogs.debugLogs.forEach(
      (log) =>
        (debugLogsMap.has(log) &&
          debugLogsMap.set(log, debugLogsMap.get(log) + 1)) ||
        debugLogsMap.set(log, 1),
    );

    appetizeLogs.metroBundlerLogs.forEach(
      (log) =>
        (metroBundlerLogsMap.has(log) &&
          metroBundlerLogsMap.set(log, metroBundlerLogsMap.get(log) + 1)) ||
        metroBundlerLogsMap.set(log, 1),
    );

    return {
      debugLogs: [...debugLogsMap.entries()].map(([log, appearanceCount]) => {
        const [logTimestamp, logMessage] = log.split("!-!");

        // return [log.split("!-!")[1], appearanceCount]

        return {
          logTimestamp,
          appearanceCount,
          log: logMessage,
        };
      }),
      metroBundlerLogs: [...metroBundlerLogsMap.entries()].map(
        ([log, appearanceCount]) => {
          const [logTimestamp, logMessage] = log.split("!-!");

          // return [log.split("!-!")[1], appearanceCount]

          return {
            logTimestamp,
            appearanceCount,
            log: logMessage,
          };
        },
      ),
    };
  }

  static getDefaultAppStatus({ is_ios_default_app, is_android_default_app }) {
    return {
      isIosDefaultApp: is_ios_default_app,
      isAndroidDefaultApp: is_android_default_app,
    };
  }
}
