import React from "react";
import sidebarExpandCollapseIcon from "../../../assets/images/sidebar-expand-collapse-icon.png";
import { ReactComponent as SidebarHandle } from "../../../assets/images/sidebar-handle.svg";
import {
  setLeftSidebarStatus,
  setRightSidebarStatus,
} from "../../../global-state";
import { useAppContext } from "../../../hooks";
import "./sidebar.scss";

/**
 * A generic Sidebar component.
 * Can be used for left sidebar or right sidebar.
 * Provides the sidebar the functionality to collapse/expand.
 * Renders the content (children) in the sidebar.
 */
export const Sidebar = ({ isLeftSidebar, children }) => {
  const [ui, dispatch] = useAppContext((state) => state.ui);
  const { isLightMode, isLeftSidebarCollapsed, isRightSidebarCollapsed } = ui;

  const onSidebarHandleClick = () => {
    isLeftSidebar
      ? dispatch(setLeftSidebarStatus(!isLeftSidebarCollapsed))
      : dispatch(setRightSidebarStatus(!isRightSidebarCollapsed));
  };

  return (
    <section
      className={`h-full bg-white dark:bg-backgroundPrimaryDark transition-width duration-0.45 ease-linear border-solid border-sidebarBorderColour dark:border-borderDark ${
        isLeftSidebar ? "border-r" : "border-l"
      } ${
        ((isLeftSidebar && isLeftSidebarCollapsed) ||
          (!isLeftSidebar && isRightSidebarCollapsed)) &&
        "w-4"
      } ${isLeftSidebar && !isLeftSidebarCollapsed && "w-3/12"} ${
        !isLeftSidebar && !isRightSidebarCollapsed && "w-full"
      }`}
    >
      <div className="relative">
        <SidebarHandle
          className={`w-sidebarHandleWidth h-sidebarHandleHeight absolute top-sidebarHandleTop z-10 fill-white dark:fill-backgroundPrimaryDark sidebar-handle ${
            !isLightMode && "sidebar-handle-dark"
          } ${
            isLeftSidebar
              ? "left-full"
              : "left-sidebarHandleRight transform rotate-180"
          }`}
        />
        <img
          src={sidebarExpandCollapseIcon}
          alt="Sidbar expand/collapse icon"
          className={`max-w-sidebarHandleIconWidth h-sidebarHandleIconHeight absolute top-sidebarHandleIconTop z-10 cursor-pointer transition-transform duration-0.45 ease-linear ${
            isLeftSidebar
              ? "left-sidebarHandleLeftImage"
              : `left-sidebarHandleRightImage transform ${
                  isRightSidebarCollapsed ? "rotate-0" : "rotate-180"
                }`
          } ${
            isLeftSidebarCollapsed && isLeftSidebar && "transform rotate-180"
          }`}
          onClick={onSidebarHandleClick}
        />
      </div>

      <div
        className={`w-full h-full overflow-hidden transition-transform duration-0.45 ease-linear ${
          isLeftSidebar && isLeftSidebarCollapsed
            ? "transform scale-x-0"
            : "transform scale-x-100"
        }`}
      >
        {children}
      </div>
    </section>
  );
};
