import React from "react";
import { ToggleButton } from "./components";

/**
 * A generic toggle component.
 * Used for theme toggle and debug/static toggle.
 */
export const Toggle = ({
  leftButtonContent,
  rightButtonContent,
  isLeftSelected,
  isDisabled,
  isLeftButtonDisabled,
  isRightButtonDisabled,
  onChange,
}) => {
  return (
    <div
      className={`flex items-center gap-x-1 rounded-lg ${
        isDisabled
          ? "border border-solid border-dropdownBorder dark:border-borderDark bg-toggleDisabledBackground dark:bg-backgroundSecondaryDark"
          : "bg-toggleDisabledBackground dark:bg-backgroundSecondaryDark dark:border dark:border-solid dark:border-borderDark cursor-pointer"
      }`}
    >
      <ToggleButton
        id="leftButton"
        isSelected={isLeftSelected}
        isDisabled={isDisabled || isLeftButtonDisabled}
        areBothDisabled={isDisabled}
        content={leftButtonContent}
        onClick={() => onChange(true)}
      />
      <ToggleButton
        id="rightButton"
        isSelected={!isLeftSelected}
        isDisabled={isDisabled || isRightButtonDisabled}
        areBothDisabled={isDisabled}
        content={rightButtonContent}
        onClick={() => onChange(false)}
      />
    </div>
  );
};
