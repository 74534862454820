import React from "react";
import { EmulatorModeToggle, Scale } from "./components";

export const MidPaneTop = () => {
  return (
    <div className="w-full p-7 flex justify-between items-center flex-wrap gap-y-4">
      <EmulatorModeToggle />
      <Scale />
    </div>
  );
};
