/**
 * Defines all action types and action creators regarding emulator options.
 */

export const SET_AVAILABLE_DEVICES = "SET_AVAILABLE_DEVICES";
export const setAvailableDevices = (availableDevices) => ({
  type: SET_AVAILABLE_DEVICES,
  payload: availableDevices,
});

export const SET_OS = "SET_OS";
export const setOs = (os) => ({ type: SET_OS, payload: os });

export const SET_OS_VERSION = "SET_OS_VERSION";
export const setOsVersion = (osVersion) => ({
  type: SET_OS_VERSION,
  payload: osVersion,
});

export const SET_DEVICE = "SET_DEVICE";
export const setDevice = (device) => ({ type: SET_DEVICE, payload: device });

export const SET_SCALE = "SET_SCALE";
export const setScale = (scale) => ({ type: SET_SCALE, payload: scale });

export const SET_EMULATOR_OPTIONS_LOADING = "SET_EMULATOR_OPTIONS_LOADING";
export const setEmulatorOptionsLoading = (isLoading) => ({
  type: SET_EMULATOR_OPTIONS_LOADING,
  payload: isLoading,
});

export const SET_EMULATOR_OPTIONS_ERROR = "SET_EMULATOR_OPTIONS_ERROR";
export const setEmulatorOptionsError = (errorMessage) => ({
  type: SET_EMULATOR_OPTIONS_ERROR,
  payload: errorMessage,
});

export const SET_ROTATION = "SET_ROTATION";
export const setRotation = (rotationConfig) => ({
  type: SET_ROTATION,
  payload: rotationConfig,
});

export const SET_EMULATOR_MODE = "SET_EMULATOR_MODE";
export const setEmulatorMode = (isStaticMode) => ({
  type: SET_EMULATOR_MODE,
  payload: isStaticMode,
});
