import { faro } from "@grafana/faro-web-sdk";
import React, { useEffect, useRef, useState } from "react";
import {
  setClientStatus,
  setPublicKeyInvalid,
  setClientLoading,
  setDebugLogs,
  setMetroBundlerLogs,
  setAndroidDebugStatus,
  setIosDebugStatus,
  setSession,
  setDevice,
  setOsVersion,
  setRotationStatus,
  setEmulatorMode,
  setMessages,
  setSessionStatus,
  setBundlerConfigStatus,
  APPETIZE_APP_NOT_FOUND_ERROR,
  BUNDLER_CONNECTION_ERROR,
  DNS_BUNDLER_ERROR,
  APPETIZE_DEBUG_AUTH_ERROR,
  clearMetroBundlerLogs,
  clearDebugLogs,
  setAutomatedStepsFailureModalStatus,
  BUNDLER_AUTOMATED_STEPS_FAILURE_ERROR,
} from "../../../../../../global-state";
import { useAppContext } from "../../../../../../hooks";
import {
  AppetizeClientService,
  DataTransformerService,
  EmulatorHelperService,
} from "../../../../../../services";
import "./appetize-client.scss";

const { getInternalLog } = EmulatorHelperService;

/**
 * Represents the Android or iOS device being rendered on the screen.
 * Manages the client and session for a particular device.
 */
export const AppetizeClient = ({ isAndroidClient, onError }) => {
  const [emulatorOptions, ide, appetize, ui, dispatch] = useAppContext(
    (state) => state.emulatorOptions,
    (state) => state.ide,
    (state) => state.appetize,
    (state) => state.ui
  );
  const { device, osVersion, scale } = emulatorOptions.selected;
  const { rotation, isStaticMode, available } = emulatorOptions;
  const { androidPublicKey, iosPublicKey, bundlerUrl } = ide;
  const {
    isClientLoading,
    invalidPublicKeys,
    isAndroidDebugEnabled,
    isIosDebugEnabled,
    defaultConfigurations,
  } = appetize;
  const { android: defaultAndroidConfig, ios: defaultIosConfig } =
    defaultConfigurations;
  const { isLightMode, isLeftSidebarCollapsed, isRightSidebarCollapsed } = ui;

  const defaultDevice = isAndroidClient
    ? defaultAndroidConfig.device
    : defaultIosConfig.device;
  const defaultOsVersion = isAndroidClient
    ? defaultAndroidConfig.osVersion
    : defaultIosConfig.osVersion;
  const publicKey = isAndroidClient ? androidPublicKey : iosPublicKey;

  const [initialScaledStyles, setInitialScaledStyles] = useState(null);
  const [computedScaledStyles, setComputedScaledStyles] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [deviceOrientation, setDeviceOrientation] = useState(null);

  const deviceRef = useRef(device);
  const osVersionRef = useRef(osVersion);
  const staticModeRef = useRef(isStaticMode);
  const appetizeClientServiceRef = useRef(null);
  const bundlerConnectionIdRef = useRef(null);

  /**
   * Run side-effects on mount.
   */
  useEffect(() => {
    /**
     * If the public key is invalid show error and bail out from client initialization and set-up.
     */
    if (invalidPublicKeys.includes(publicKey)) {
      const appNotAvailableErrorMessage = getAppErrorMessage(
        isAndroidClient ? "Android" : "iOS"
      );

      onError(appNotAvailableErrorMessage);

      // Also, report the app not available error to Grafana.
      faro.api.pushError(new Error(appNotAvailableErrorMessage));

      return;
    }

    /**
     * Create a new instance of Client Service and store it in a ref for future reference.
     */
    const appetizeClientService = new AppetizeClientService();
    appetizeClientServiceRef.current = appetizeClientService;

    /**
     * Attach event handlers to the Client Service.
     */
    appetizeClientServiceRef.current.on(
      "beforeClientLoadingStarts",
      clientLoadStartHandler
    );

    appetizeClientServiceRef.current.on(
      "clientLoadingSuccess",
      clientLoadSuccessHandler
    );

    appetizeClientServiceRef.current.on(
      "clientLoadingFailed",
      clientLoadFailureHandler
    );

    appetizeClientServiceRef.current.on(
      "clientLoadingEnded",
      clientLoadEndHandler
    );

    appetizeClientServiceRef.current.on(
      "beforeClientConfigUpdate",
      clientConfigUpdateStartHandler
    );

    appetizeClientServiceRef.current.on(
      "afterClientConfigUpdate",
      clientConfigUpdateHandler
    );

    appetizeClientServiceRef.current.on("clientError", clientErrorHandler);

    appetizeClientServiceRef.current.on(
      "sessionRequested",
      sessionRequestHandler
    );

    appetizeClientServiceRef.current.on("session", sessionHandler);

    appetizeClientServiceRef.current.on("deviceInfo", deviceInfoHandler);

    appetizeClientServiceRef.current.on(
      "beforeBundlerConnectionStarts",
      bundlerConnectionStartHandler
    );

    appetizeClientServiceRef.current.on(
      "bundlerConnectionIdGenerated",
      bundlerConnectionIdHandler
    );

    appetizeClientServiceRef.current.on(
      "dnsBundlerNotRunning",
      dnsBundlerNotRunningHandler
    );

    appetizeClientServiceRef.current.on(
      "existingSessionUsedForBundlerConfig",
      bundlerConfiguredInExistingSessionHandler
    );

    appetizeClientServiceRef.current.on(
      "bundlerUrlConfigStarted",
      bundlerUrlConfigStartHandler
    );

    appetizeClientServiceRef.current.on(
      "connectedToBundler",
      bundlerConnectionSuccessHandler
    );

    appetizeClientServiceRef.current.on(
      "bundlerConfigurationStepsFailed",
      bundlerConfigurationStepsFailureHandler
    );

    appetizeClientServiceRef.current.on(
      "bundlerConnectionFailed",
      bundlerConnectionFailureHandler
    );

    appetizeClientServiceRef.current.on(
      "bundlerConnectionCancelled",
      bundlerConnectionCancelHandler
    );

    /**
     * Load the client with default confiurations.
     */
    (async () => await loadClient())();

    /**
     * Run clean-up steps on unmount.
     */
    return () => {
      /**
       * If Metro Bundler connection exists cancel the connection.
       */
      bundlerConnectionIdRef.current &&
        (async () =>
          await appetizeClientServiceRef.current.cancelBundlerConnection(
            bundlerConnectionIdRef.current,
            false
          ))();

      /**
       * Set the session to null in global state for other components to know that session is terminated.
       */
      dispatch(setSession(null));

      /**
       * Toggle the Emulator Mode back to Static.
       */
      dispatch(setEmulatorMode(true));

      /**
       * Clear both Debug and Metro Bundler logs.
       */
      dispatch(clearDebugLogs());
      dispatch(clearMetroBundlerLogs());
    };
  }, []);

  /**
   * Update Device and OS Version to user selection.
   * And change device color and appearence based on theme.
   */
  useEffect(() => {
    const appetizeClientService = appetizeClientServiceRef.current;
    const client = appetizeClientService && appetizeClientService.getClient();

    if (appetizeClientService && client) {
      /**
       * If pre-selected OS Version is not available then select the default OS Version if available for the Device.
       * Else select the first available OS Version as it is what Appetize uses by default.
       */
      const availableOsVersions =
        DataTransformerService.getOsVersionOptionsForDevice(
          available.devices,
          isAndroidClient ? "android" : "ios",
          device
        );

      const isOsVersionAvailable = !!availableOsVersions.find(
        ({ value: availableOsVersion }) => availableOsVersion === osVersion
      );

      const isDefaultOsVersionAvailable = !!availableOsVersions.find(
        ({ value: availableOsVersion }) =>
          availableOsVersion === defaultOsVersion
      );

      !isOsVersionAvailable &&
        dispatch(
          setOsVersion(
            isDefaultOsVersionAvailable
              ? defaultOsVersion
              : availableOsVersions[0].value
          )
        );

      appetizeClientService.updateClientConfigurations({
        device: device || "",
        osVersion: osVersion || "",
        deviceColor: isLightMode ? "white" : "black",
        appearance: isLightMode ? "light" : "dark",
      });
    }
  }, [device, osVersion, isLightMode]);

  /**
   * Cancel the existing Metro Bundler connection on either Device or OS Version change.
   * Also, clear both Debug and Metro Bundler logs.
   */
  useEffect(() => {
    deviceRef.current = device;
    osVersionRef.current = osVersion;

    bundlerConnectionIdRef.current &&
      (async () =>
        await appetizeClientServiceRef.current.cancelBundlerConnection(
          bundlerConnectionIdRef.current,
          true
        ))();

    dispatch(clearDebugLogs());
    dispatch(clearMetroBundlerLogs());
  }, [device, osVersion]);

  /**
   * Rescale the device in the container when Scale or Orientation changes, or if Sidebars are expanded or collapsed.
   */
  useEffect(() => {
    rescaleDevice();
  }, [
    scale,
    deviceOrientation,
    isLeftSidebarCollapsed,
    isRightSidebarCollapsed,
  ]);

  /**
   * Calculate the dimensions for the iframe container when device dimensions change.
   */
  useEffect(() => {
    deviceInfo &&
      scale !== "auto" &&
      initialScaledStyles &&
      !computedScaledStyles &&
      setComputedScaledStyles(getComputedScaledStyles());
  }, [deviceInfo]);

  /**
   * Rotate the device when Rotate buttons are clicked.
   */
  useEffect(() => {
    const session = appetizeClientServiceRef.current?.getSession();

    rotation && session && rotateDevice();
  }, [rotation]);

  /**
   * Connect to the Metro Bundler when Emulator Mode changes to Local.
   * Cancel the Metro Bundler connection when Emulator Mode changes to Static.
   */
  useEffect(() => {
    staticModeRef.current = isStaticMode;

    const client = appetizeClientServiceRef.current?.getClient();

    !isStaticMode &&
      client &&
      (async () =>
        await appetizeClientServiceRef.current.connectToMetroBundler(
          bundlerUrl
        ))();

    isStaticMode &&
      bundlerConnectionIdRef.current &&
      (async () =>
        await appetizeClientServiceRef.current.cancelBundlerConnection(
          bundlerConnectionIdRef.current,
          true
        ))();
  }, [isStaticMode]);

  /**
   * Perform following tasks before client loading starts.
   */
  const clientLoadStartHandler = (defaultConfigurations) => {
    // Set client loading status to true in global state for other components to react accordingly.
    dispatch(setClientLoading(true));

    // Set client ready/enabled status to false in global state for other components to react accordingly.
    dispatch(setClientStatus(false));

    /**
     * Map the default device and OS Version to Device and OS Version Dropdown menus, respectively.
     * This is because the client will be loaded with default device and OS Version.
     */
    dispatch(setDevice(defaultDevice));
    dispatch(setOsVersion(defaultOsVersion));

    // Report the event to Grafana.
    faro.api.pushEvent("load_client_start", {
      data: JSON.stringify(defaultConfigurations),
    });
  };

  /**
   * Set client ready/enabled status to true in global state for other components to react accordingly.
   */
  const clientLoadSuccessHandler = (defaultConfigurations) => {
    dispatch(setClientStatus(true));

    // Report the event to Grafana.
    faro.api.pushEvent("load_client_success", {
      data: JSON.stringify(defaultConfigurations),
    });
  };

  /**
   * Perform following task when client loading fails.
   */
  const clientLoadFailureHandler = (defaultConfigurations) => {
    // Set client ready/enabled status to false in global state for other components to react accordingly.
    dispatch(setClientStatus(false));

    // Set the Android/iOS Public Key as invalid.
    dispatch(setPublicKeyInvalid(publicKey));

    const appNotAvailableErrorMessage = getAppErrorMessage(
      isAndroidClient ? "Android" : "iOS"
    );

    // Show Android/iOS app not available error message.
    onError(appNotAvailableErrorMessage);

    // Report the event to Grafana.
    faro.api.pushEvent("load_client_failed", {
      data: JSON.stringify(defaultConfigurations),
    });

    // Report app not available error to Grafana
    faro.api.pushError(new Error(appNotAvailableErrorMessage));
  };

  /**
   * Set client loading status to false in global state to allow other components to react accordingly.
   */
  const clientLoadEndHandler = () => dispatch(setClientLoading(false));

  /**
   * Repor the event and configurations to Grafana before client configurations are updated.
   */
  const clientConfigUpdateStartHandler = (configurations) =>
    faro.api.pushEvent("update_client_config", {
      data: JSON.stringify(configurations),
    });

  /**
   * Perform following tasks after client configurations have been updated.
   */
  const clientConfigUpdateHandler = (configurations) => {
    // Rescale the device in the iframe container.
    rescaleDevice();

    // Report the eventand configurations to Grafana.
    faro.api.pushEvent("update_client_config_success", {
      data: JSON.stringify(configurations),
    });
  };

  /**
   * Perform following tasks when client throws an error.
   */
  let clientErrorHandler = async ({ message }) => {
    /**
     * If the error is debug logs not enabled then show the log for this in logs tab and restart the session.
     * Also, set the debug configuration on client to false as the debug logs are not enabled for the app and this will help prevent future errors.
     */
    if (
      ((isAndroidClient && isAndroidDebugEnabled) ||
        (!isAndroidClient && isIosDebugEnabled)) &&
      message === APPETIZE_DEBUG_AUTH_ERROR
    ) {
      try {
        const client = appetizeClientServiceRef.current.getClient();
        await client.setConfig({ debug: false });
        await client.startSession();

        dispatch(
          isAndroidClient
            ? setAndroidDebugStatus(false)
            : setIosDebugStatus(false)
        );
      } catch (error) {
        error.toString().includes("Session failed to start") &&
          dispatch(
            isAndroidClient
              ? setAndroidDebugStatus(false)
              : setIosDebugStatus(false)
          );
      }
    }

    // Report the event to Grafana.
    faro.api.pushEvent("error_client", { message });
  };

  /**
   * Perform following tasks when a new session is requested.
   */
  let sessionRequestHandler = async () => {
    /**
     * Show the session started log in the logs tab.
     */
    const sessionRequestLog = getInternalLog(
      `Session requested with following configurations - OS: ${
        isAndroidClient ? "Android" : "iOS"
      }, Device: ${deviceRef.current}, OS Version: ${osVersionRef.current}`
    );
    dispatch(setDebugLogs(sessionRequestLog));
    !staticModeRef.current && dispatch(setMetroBundlerLogs(sessionRequestLog));

    // Set session loading status to true in global state to allow other components to react accordingly.
    dispatch(setSessionStatus(true));

    // Report the event to Grafana.
    faro.api.pushEvent("request_session", {
      data: JSON.stringify({
        os: isAndroidClient ? "Android" : "iOS",
        device: deviceRef.current,
        osVersion: osVersionRef.current,
      }),
    });
  };

  /**
   * Perform following tasks when session has started/is available.
   */
  let sessionHandler = (session) => {
    const sessionData = {
      os: session.app.platform === "android" ? "Android" : "iOS",
      device: session.device.name,
      osVersion: session.device.osVersion,
    };

    /**
     * Attach event handler for log event.
     * Show the log in the logs tab.
     */
    session.on("log", ({ message }) => {
      const sessionLog = `${Date.now()}!-!${message}`;
      dispatch(setDebugLogs(sessionLog));
    });

    /**
     * Attach event handler for session disconnect event.
     */
    session.on("disconnect", async () => {
      /**
       * Set the session to null on Client Service and in global state.
       */
      appetizeClientServiceRef.current.setSession(null);
      dispatch(setSession(null));

      // Set session loading status to false in global state to allow other components to react accordingly.
      dispatch(setSessionStatus(false));

      // Toggle the Emulator Mode back to Static.
      dispatch(setEmulatorMode(true));

      /**
       * Metro Bundler and Other tabs have been disabled for V1.
       * Commenting tab selection code out as only Debug tab is available.
       */
      // dispatch(setSelectedTab("debug-tab"));

      /**
       * Set the device orientation back to portrait.
       */
      await appetizeClientServiceRef.current
        .getClient()
        .setConfig({ orientation: "portrait" });
      rescaleDevice();

      // Report the event to Grafana.
      faro.api.pushEvent("disconnect_session", {
        data: JSON.stringify(sessionData),
      });
    });

    /**
     * Attach event handler for orientation changed event.
     * Set the device orientation state to received orientation.
     */
    session.on("orientationChanged", (orientation) => {
      setDeviceOrientation(orientation);

      // Report the event to Grafana.
      faro.api.pushEvent("change_orientation", { orientation });
    });

    /**
     * Show session started log in logs tab.
     */
    const sessionStartLog = getInternalLog(
      `Session started for following configurations - OS: ${
        session.app.platform === "android" ? "Android" : "iOS"
      }, Device: ${session.device.name}, OS Version: ${
        session.device.osVersion
      }`
    );
    dispatch(setDebugLogs(sessionStartLog));
    !staticModeRef.current && dispatch(setMetroBundlerLogs(sessionStartLog));

    /**
     * Set the session to received session object on Client Service and in global state.
     */
    appetizeClientServiceRef.current.setSession(session);
    dispatch(setSession(session));

    // Set session loading status to false in global state to allow other components to react to it.
    dispatch(setSessionStatus(false));

    // Report the event to Grafana.
    faro.api.pushEvent("session_success", {
      data: JSON.stringify(sessionData),
    });
  };

  /**
   * Set the device info (for device dimensions) in state when device info event is emitted.
   */
  const deviceInfoHandler = (deviceInfo) => setDeviceInfo(deviceInfo);

  /**
   * Perform following tasks before connecting to Metro Bundler
   */
  const bundlerConnectionStartHandler = () => {
    // Set the bundler configuring status to true.
    dispatch(setBundlerConfigStatus(true));

    // Report the event to Grafana.
    faro.api.pushEvent("start_bundler_configuration", { bundlerUrl });
  };

  /**
   * Set the bundler connection ID in state when connection ID is generated for Metro Bundler connection.
   */
  const bundlerConnectionIdHandler = (bundlerConnectionId) =>
    (bundlerConnectionIdRef.current = bundlerConnectionId);

  /**
   * Perform following task when Metro Bundler is not running in IDE (502 DNS Error).
   */
  const dnsBundlerNotRunningHandler = () => {
    const dnsBundlerError = EmulatorHelperService.getMessage(
      DNS_BUNDLER_ERROR,
      true
    );

    // Show "Bundler not running" error.
    dispatch(setMessages([dnsBundlerError]));

    // Report the error to Grafana.
    faro.api.pushError(new Error(dnsBundlerError.message));

    // Toggle Emulator Mode back to Static.
    dispatch(setEmulatorMode(true));
  };

  /**
   * Show existing session used log in logs tab when connection to Metro Bundler is made in an existing session.
   */
  const bundlerConfiguredInExistingSessionHandler = () => {
    const session = appetizeClientServiceRef.current.getSession();

    const usingSessionLog = getInternalLog(
      `Connecting to Metro Bundler using current session with following configurations - OS: ${
        session.app.platform === "android" ? "Android" : "iOS"
      }, Device: ${session.device.name}, OS Version: ${
        session.device.osVersion
      }`
    );
    dispatch(setDebugLogs(usingSessionLog));
    dispatch(setMetroBundlerLogs(usingSessionLog));

    // Report the log to Grafana.
    faro.api.pushLog(["Configuring bundler in existing session."]);
  };

  /**
   * Show bundler URL config started log in logs tab when Metro Bundler URL is being configured.
   */
  const bundlerUrlConfigStartHandler = () => {
    const metroBundlerConfigureLog = getInternalLog(
      `Configuring Metro Bundler URL. Connecting to - https://${bundlerUrl}:80`
    );
    dispatch(setDebugLogs(metroBundlerConfigureLog));
    dispatch(setMetroBundlerLogs(metroBundlerConfigureLog));
  };

  /**
   * After connection to Metro Bundler is successfully established do following.
   */
  const bundlerConnectionSuccessHandler = () => {
    // Set bundler configuring status to false in global state to allow other component to react accordingly.
    dispatch(setBundlerConfigStatus(false));

    /**
     * Show connected to bundler log in logs tab.
     */
    const metroBundlerConnectedLog = getInternalLog(
      `Connected to Metro Bundler at - https://${bundlerUrl}:80`
    );
    dispatch(setDebugLogs(metroBundlerConnectedLog));
    dispatch(setMetroBundlerLogs(metroBundlerConnectedLog));

    // Report the event to Grafana.
    faro.api.pushEvent("bundler_configuration_success", { bundlerUrl });
  };

  /**
   * Perform following tasks when Metro Bundler configuration steps fail.
   */
  const bundlerConfigurationStepsFailureHandler = () => {
    // Perform all the same tasks as when connection would have failed, but do not show the connection failed error.
    bundlerConnectionFailureHandler(false);

    // Show bundler configuration steps failure modal (Automated Steps Failure Modal).
    dispatch(setAutomatedStepsFailureModalStatus(true));

    // Report the error to Grafana.
    faro.api.pushError(new Error(BUNDLER_AUTOMATED_STEPS_FAILURE_ERROR));
  };

  /**
   * Perform following tasks when Metro Bundler connection fails.
   */
  const bundlerConnectionFailureHandler = (
    shouldShowBundlerConnectionError = true
  ) => {
    // Toggle Emulator Mode back to Static.
    dispatch(setEmulatorMode(true));

    /**
     * Metro Bundler and Other tabs have been disabled for V1.
     * Commenting tab selection code out as only Debug tab is available.
     */
    // dispatch(setSelectedTab("debug-tab"));

    const bundlerConnectionError = EmulatorHelperService.getMessage(
      BUNDLER_CONNECTION_ERROR,
      true
    );

    // Show bundler connection failed error.
    shouldShowBundlerConnectionError &&
      dispatch(setMessages([bundlerConnectionError]));

    // Report the error to Grafana.
    faro.api.pushError(new Error(bundlerConnectionError.message));

    !appetizeClientServiceRef.current.getSession() &&
      dispatch(setSessionStatus(false));
  };

  /**
   * Perform following tasks when Metro Bundler connection is cancelled.
   */
  const bundlerConnectionCancelHandler = () => {
    // Set bundler configuring status to false
    dispatch(setBundlerConfigStatus(false));

    // Report the event to Grafana.
    faro.api.pushEvent("cancel_bundler_configuration", { bundlerUrl });
  };

  /**
   * Load/initialize the Appetize Client with default configurations.
   */
  const loadClient = async () =>
    await appetizeClientServiceRef.current.loadClient(
      `#appetize-${isAndroidClient ? "android" : "ios"}`,
      {
        publicKey,
        device: defaultDevice,
        osVersion: defaultOsVersion,
        scale: "auto",
        centered: "horizontal",
        deviceColor: isLightMode ? "white" : "black",
        appearance: isLightMode ? "light" : "dark",
        toast: "top",
        debug: isAndroidClient ? isAndroidDebugEnabled : isIosDebugEnabled,
        audio: true,
      }
    );

  /**
   * Get app not found error message for the selected OS.
   */
  const getAppErrorMessage = (os) => {
    return APPETIZE_APP_NOT_FOUND_ERROR.replace("{OS_REPLACE}", os);
  };

  /**
   * Calculate intial dimensions of iframe container.
   * Intial dimensions are always based on selected scale value.
   */
  const getInitialScaledStyles = () => {
    const scaleMapping = {
      25: 1,
      50: 2,
      75: 3,
      100: 4,
    };

    const scaledSize =
      scale === "auto" ? "100%" : `${scale + 10 * scaleMapping[scale]}%`;

    return {
      width: scaledSize,
      height: scaledSize,
    };
  };

  /**
   * Calculate dimensions of the iframe container based on rendered device's dimensions.
   */
  const getComputedScaledStyles = () => {
    const { width: deviceWidth, height: deviceHeight } = deviceInfo.embed;

    return {
      width: `${deviceWidth}px`,
      height: `${deviceHeight}px`,
    };
  };

  /**
   * Force the iframe container to resize itself as per rendered device.
   */
  const rescaleDevice = () => {
    setInitialScaledStyles(getInitialScaledStyles());
    setComputedScaledStyles(null);
    setDeviceInfo(null);
  };

  /**
   * Rotate the device.
   */
  const rotateDevice = async () => {
    try {
      // Set rotating status to true in global state before rotating.
      dispatch(setRotationStatus(true));

      /**
       * Rotate the device
       */
      const session = appetizeClientServiceRef.current.getSession();
      await session.rotate(rotation.direction);

      // Set rotating status to false in global state after successfully rotating the device.
      dispatch(setRotationStatus(false));
    } catch (error) {
      // Set rotating status to false in global state after failing to rotate the device.
      dispatch(setRotationStatus(false));
    }
  };

  /**
   * Render the iframe to allow Appetize to render device in it.
   * Render the iframe in iframe container to implement custom scaling.
   * Instead of scaling the device inside iframe, iframe container is being scaled to rendered device's dimensions.
   */
  return (
    <div
      className={`appetize-client-container w-full h-full ${
        isClientLoading ? "overflow-hidden" : "overflow-auto"
      } ${isLightMode ? "light-scrollbar" : "dark-scrollbar"}`}
    >
      <div
        className="mx-auto my-0 transition-width transition-height duration-200 ease-linear"
        style={{ ...initialScaledStyles, ...computedScaledStyles }}
      >
        <iframe
          id={`appetize-${isAndroidClient ? "android" : "ios"}`}
          frameBorder="0"
          className={`w-full h-full ${
            ((appetizeClientServiceRef.current &&
              !appetizeClientServiceRef.current.getClient()) ||
              isClientLoading) &&
            "hidden"
          }`}
        ></iframe>
      </div>
    </div>
  );
};
