import { faro } from "@grafana/faro-web-sdk";
import React from "react";
import {
  clearDebugLogs,
  clearMetroBundlerLogs,
} from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import { FooterButton } from "../FooterButton";

export const ClearLogs = ({ logsByDuplicacy }) => {
  const [ui, dispatch] = useAppContext((state) => state.ui);
  const { selectedTab } = ui;

  const onClearButtonClick = () => {
    dispatch(
      selectedTab === "debug-tab" ? clearDebugLogs() : clearMetroBundlerLogs()
    );

    faro.api.pushEvent("clear_logs");
  };

  return (
    <FooterButton
      title="Clear Logs"
      logsByDuplicacy={logsByDuplicacy}
      onClick={onClearButtonClick}
    >
      &#8854;
    </FooterButton>
  );
};
