import React from "react";
import { useAppContext } from "../../../../hooks";

export const FooterButton = ({
  title,
  logsByDuplicacy,
  children,
  extraClasses,
  onClick,
}) => {
  const [ui] = useAppContext((state) => state.ui);
  const { selectedTab } = ui;

  return (
    <button
      className={`w-[38px] h-[38px] bg-white dark:bg-backgroundPrimaryDark shadow text-xl text-black dark:text-primaryDark dark:border dark:border-solid dark:border-borderDark rounded-md font-medium hover:font-semibold disabled:hover:font-medium disabled:text-inactiveTextColour disabled:dark:text-disabledDark disabled:cursor-default ${extraClasses?.join(
        " ",
      )}`}
      title={title}
      onClick={onClick}
      disabled={
        !logsByDuplicacy ||
        (selectedTab === "debug-tab" && !logsByDuplicacy.debugLogs.length) ||
        (selectedTab === "metro-bundler-tab" &&
          !logsByDuplicacy.metroBundlerLogs.length)
      }
    >
      {children}
    </button>
  );
};
