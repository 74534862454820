import React from "react";
import { ReactComponent as LightModeIcon } from "../../../../assets/images/light-mode-icon.svg";
import { ReactComponent as DarkModeIcon } from "../../../../assets/images/dark-mode-icon.svg";
import { setTheme } from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import { Toggle } from "../../../shared";
import "./theme-toggle.scss";

/**
 * Theme toggler.
 * Toggles the theme from light to dark and vice-versa.
 * Consumes the theme context and updates the theme state slice based on user selection.
 */
export const ThemeToggle = () => {
  const [ui, dispatch] = useAppContext((state) => state.ui);
  const { isLightMode } = ui;

  const onChange = (isLightMode) => {
    dispatch(setTheme(isLightMode));
  };

  return (
    <div className="px-6 py-6">
      <Toggle
        leftButtonContent={
          <LightModeIcon
            className={`${
              isLightMode
                ? "toggle-button-active"
                : "toggle-button-inactive-dark"
            }`}
          />
        }
        rightButtonContent={
          <DarkModeIcon
            className={`${
              isLightMode
                ? "toggle-button-inactive"
                : "toggle-button-active-dark"
            }`}
          />
        }
        isLeftSelected={isLightMode}
        onChange={onChange}
      />
    </div>
  );
};
