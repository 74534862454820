/**
 * A state slice.
 * Used to store Appetize.io related state.
 */
export const appetize = {
  isClientReady: false,
  isClientLoading: false,
  session: null,
  isSessionLoading: false,
  invalidPublicKeys: [],
  appetizeLogs: {
    debugLogs: [],
    metroBundlerLogs: [],
  },
  isAndroidDebugEnabled: true,
  isIosDebugEnabled: true,
  defaultConfigurations: {
    ios: {
      device: "iphone8",
      osVersion: "14.5",
    },
    android: {
      device: "pixel4",
      osVersion: "11.0",
    },
  },
  isRotating: false,
  isConfiguringBundler: false,
};
