import React from "react";

/**
 * A generic button component for Toggle.
 * Used as a button only in Toggle component.
 */
export const ToggleButton = ({
  id,
  isSelected,
  isDisabled,
  areBothDisabled,
  content,
  onClick,
}) => {
  const activeClasses = `rounded-lg ${
    isSelected
      ? "bg-white dark:bg-backgroundPrimaryDark transform scale-105 dark:scale-110 shadow text-black dark:text-primaryDark dark:border dark:border-solid dark:border-borderDark"
      : "text-inactiveTextColour dark:text-disabledDark"
  }`;

  const disabledClasses = `text-inactiveTextColour dark:text-disabledDark cursor-default ${
    id === "leftButton" &&
    areBothDisabled &&
    "border-r border-solid border-dropdownBorder dark:border-borderDark"
  }`;

  return (
    <button
      id={id}
      className={`p-2 transition-transform duration-0.45 ease-linear ${
        isDisabled ? disabledClasses : activeClasses
      }`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {content}
    </button>
  );
};
