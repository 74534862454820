export class EmulatorHelperService {
  static currentUniqueId = 0;

  static getInternalLog(message) {
    const currentTimestamp = Date.now();
    const currentDateTime = new Date(currentTimestamp);
    const formattedDateTime = `${currentDateTime.getFullYear()}-${
      currentDateTime.getMonth() + 1
    }-${currentDateTime.getDate()} ${currentDateTime.getHours()}:${currentDateTime.getMinutes()}:${currentDateTime.getSeconds()}`;

    return `${currentTimestamp}!-!${formattedDateTime} - INTERNAL_IDE_LOG - ${message}`;
  }

  static getMessage(message, isError) {
    return {
      id: ++this.currentUniqueId,
      message,
      isError,
    };
  }
}
