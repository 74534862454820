import { SET_IDE_DETAILS } from "../actions";

export const ideReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_IDE_DETAILS:
      return {
        ...state,
        ide: {
          ...state.ide,
          ...payload,
        },
      };
    default:
      return state;
  }
};
