import React from "react";
import {
  BuilderIdeLogo,
  EmulatorOptions,
  GeneralInfoLink,
  ThemeToggle,
  Utilities,
} from "./components";
import { useAppContext } from "../../hooks";
import "./left-sidebar.scss";

/**
 * Renders the content in Left Sidebar.
 * Renders Builder IDE logo, parameter options and theme toggle.
 */
export const LeftSidebar = () => {
  const [ui] = useAppContext((state) => state.ui);
  const { isLightMode } = ui;

  return (
    <div className="w-full h-full flex flex-col">
      <BuilderIdeLogo />

      <div
        className={`flex-grow flex flex-col justify-between overflow-y-auto left-sidebar-content ${
          isLightMode ? "light-scrollbar" : "dark-scrollbar"
        }`}
      >
        <EmulatorOptions />

        <Utilities />

        <div>
          <GeneralInfoLink />

          <div className="w-max">
            <ThemeToggle />
          </div>
        </div>
      </div>
    </div>
  );
};
