import React from "react";

export const Toast = ({ messageId, message, isError, onClose }) => {
  const onCloseClick = () => {
    onClose(messageId);
  };

  return (
    <div
      className={`w-full min-h-[56px] flex justify-between items-center ${
        isError ? "bg-rose-200" : "bg-green-200"
      } rounded-md overflow-hidden`}
    >
      <p
        className={`px-6 py-4 flex-grow text-center ${
          isError ? "text-rose-400" : "text-green-900"
        } font-medium`}
      >
        {message}
      </p>
      {onClose && (
        <p
          className={`px-6 py-4 self-stretch flex justify-center items-center text-dropdownOption text-base font-semibold cursor-pointer ${
            isError ? "hover:bg-rose-300" : "hover:bg-emerald-300"
          } transition-colors duration-300 ease-linear`}
          onClick={onCloseClick}
        >
          &#10005;
        </p>
      )}
    </div>
  );
};
