export class AppetizeApiService {
  static async getAvailableDevices(filterByOs) {
    const response = await fetch("https://appetize.io/available-devices");
    const devices = await response.json();
    const filteredDevices = filterByOs
      ? { filterByOs: devices[filterByOs] }
      : devices;
    return filteredDevices;
  }
}
