import React from "react";
import ReactDOM from "react-dom";
import { Popup } from "./components";

export const Modal = ({ isOpen, title, content, onClose }) => {
  return ReactDOM.createPortal(
    isOpen ? <Popup title={title} content={content} onClose={onClose} /> : null,
    document.getElementById("portal-root"),
  );
};
