import { faro } from "@grafana/faro-web-sdk";
import React, { useEffect, useRef } from "react";
import { Modal } from "../../../shared";
import {
  setDefaultAppModalStatus,
  setMessages,
  setIdeDetails,
  DEFAULT_APP_CHECK_FAILURE,
} from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import { AdminApiService, EmulatorHelperService } from "../../../../services";

export const DefaultAppModal = () => {
  const [ide, emulatorOptions, appetize, ui, dispatch] = useAppContext(
    (state) => state.ide,
    (state) => state.emulatorOptions,
    (state) => state.appetize,
    (state) => state.ui
  );
  const { isAndroidDefaultApp, isIosDefaultApp, bundlerUrl } = ide;
  const { os, device, osVersion } = emulatorOptions.selected;
  const { isSessionLoading } = appetize;
  const { isDefaultAppModalOpen } = ui;

  const abortControllerRef = useRef(new AbortController());
  const isLoadingDefaultAppStatusRef = useRef(false);

  useEffect(() => {
    isSessionLoading &&
      (async () => {
        try {
          isLoadingDefaultAppStatusRef.current = true;

          const ideName = bundlerUrl && bundlerUrl.split(".")[0];
          if (ideName) {
            faro.api.pushLog([
              `Making API request to default_emu_app/${ideName} to check default app status.`,
            ]);

            const { error, isAndroidDefaultApp, isIosDefaultApp } =
              await AdminApiService.getDefaultAppStatus(
                ideName,
                abortControllerRef.current.signal
              );

            if (error) {
              handleDefaultAppCheckError();
            } else {
              dispatch(setIdeDetails({ isAndroidDefaultApp, isIosDefaultApp }));

              if (
                (os === "android" && isAndroidDefaultApp) ||
                (os === "ios" && isIosDefaultApp)
              ) {
                dispatch(setDefaultAppModalStatus(true));

                faro.api.pushLog([
                  `Default ${
                    os === "android" ? "Android" : "iOS"
                  } app detected.`,
                ]);
              }
            }
          }
        } catch (error) {
          error.name === "AbortError"
            ? faro.api.pushError(
                new Error("default_emu_app API Request aborted.")
              )
            : handleDefaultAppCheckError();
        } finally {
          isLoadingDefaultAppStatusRef.current = false;
        }
      })();
  }, [isSessionLoading]);

  useEffect(() => {
    isLoadingDefaultAppStatusRef.current && abortControllerRef.current.abort();
  }, [os, device, osVersion]);

  const handleDefaultAppCheckError = () => {
    const defaultAppCheckError = EmulatorHelperService.getMessage(
      DEFAULT_APP_CHECK_FAILURE,
      true
    );

    dispatch(setMessages([defaultAppCheckError]));
    faro.api.pushError(new Error(defaultAppCheckError.message));
  };

  const defaultAppLabel =
    (os === "android" && isAndroidDefaultApp && "Android") ||
    (os === "ios" && isIosDefaultApp && "iOS");

  const getDefaultAppModalContent = () => (
    <div className="text-sm text-slate-900 dark:text-secondaryDark">
      <p>{`You are currently loading the default ${defaultAppLabel} App;`}</p>
      <br />
      <p>
        <b>
          Please make sure you push your code, wait for a successful pipeline
          and only then will your app build be sent to the Emulator.
        </b>
      </p>
    </div>
  );

  const onDefaultAppModalClose = () => {
    dispatch(setDefaultAppModalStatus(false));
  };

  return (
    <Modal
      isOpen={isDefaultAppModalOpen}
      title={`Default ${defaultAppLabel} App Detected`}
      content={getDefaultAppModalContent()}
      onClose={onDefaultAppModalClose}
    />
  );
};
