import React from "react";

export const OptionLabel = ({ id, title }) => {
  return (
    <label
      htmlFor={id}
      className="uppercase text-xs text-parameterTitleColour dark:text-secondaryDark font-medium tracking-wide mb-1"
    >
      {title}
    </label>
  );
};
