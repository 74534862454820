class BundlerConfigError extends Error {
  constructor(errorMessage) {
    super(errorMessage);
  }
}

export class AppetizeClientService {
  #client = null;
  #session = null;
  #events = new Map();
  #bundlerConnections = new Map();

  getClient() {
    return this.#client;
  }

  setClient(client) {
    this.#client = client;
  }

  getSession() {
    return this.#session;
  }

  setSession(session) {
    this.#session = session;
  }

  on(eventName, eventHandler) {
    !this.#events.get(eventName) && this.#events.set(eventName, eventHandler);
  }

  async loadClient(appetizeIframeId, defaultConfigurations) {
    /**
     * Emitting "beforeClientLoadingStarts" event to allow consumer to execute custom logic before client loads.
     */
    this.#emit("beforeClientLoadingStarts", defaultConfigurations);

    try {
      let client = await window.appetize.getClient(
        appetizeIframeId,
        defaultConfigurations
      );

      client.on("error", this.#events.get("clientError"));
      client.on("sessionRequested", this.#events.get("sessionRequested"));
      client.on("session", this.#events.get("session"));
      client.on("deviceInfo", this.#events.get("deviceInfo"));

      this.#client = client;

      /**
       * Emitting "clientLoadSuccess" event to allow consumer to execute custom logic after client has loaded successfully.
       */
      this.#emit("clientLoadingSuccess", defaultConfigurations);
    } catch (error) {
      /**
       * Emitting "clientLoadFailed" event to allow consumer to execute custom logic when client failed to load.
       */
      this.#emit("clientLoadingFailed", defaultConfigurations);
    }

    /**
     * Emitting "clientLoadingEnded" event to allow consumer to execute custom logic after client loading is finished.
     */
    this.#emit("clientLoadingEnded");
  }

  async updateClientConfigurations(configurations) {
    /**
     * Emitting "beforeClientConfigUpdate" to allow consumer to execute custom logic before client configurations are updated.
     */
    this.#emit("beforeClientConfigUpdate", configurations);

    await this.#client.setConfig(configurations);

    /**
     * Emitting "afterClientConfigUpdate" to allow consumer to execute custom logic after configurations are updated.
     */
    this.#emit("afterClientConfigUpdate", configurations);
  }

  async connectToMetroBundler(bundlerUrl) {
    /**
     * Emitting "beforeBundlerConnectionStarts" event to allow consumer to execute custom logic before
     * connecting to Metro Bundler.
     */
    this.#emit("beforeBundlerConnectionStarts");

    /**
     * Generate a connection ID.
     * This connection ID is used to determine if the connection has been cancelled by the consumer.
     */
    const bundlerConnectionIds = [...this.#bundlerConnections.values()];
    const isConnectionPresent = !!bundlerConnectionIds.length;
    const lastConnectionId =
      isConnectionPresent &&
      bundlerConnectionIds[bundlerConnectionIds.length - 1];
    const bundlerConnectionId = isConnectionPresent ? lastConnectionId + 1 : 1;
    this.#bundlerConnections.set(bundlerConnectionId, { isActive: true });

    /**
     * Emitting "bundlerConnectionIdGenerated" event to allow consumer to store bundlerConnectionId for future reference.
     */
    this.#emit("bundlerConnectionIdGenerated", bundlerConnectionId);

    const bundlerConnection = this.#bundlerConnections.get(bundlerConnectionId);

    try {
      /**
       * Check if Metro Bundler is running on IDE.
       * If yes then continue to configure Metro Bundler URL.
       * Else end the connection.
       */
      const response = await fetch(
        `${
          process.env.REACT_APP_ENV === "dev" ? "http" : "https"
        }://${bundlerUrl}`,
        {
          mode: process.env.REACT_APP_ENV === "dev" ? "no-cors" : "cors",
        }
      );
      if (response.status === 502) {
        bundlerConnection.isActive && this.#emit("dnsBundlerNotRunning");
        return;
      }

      /**
       * If session already exists then just emitting "existingSessionUsedForBundlerConfiguration" event and using it for configuring Metro Bundler URL.
       * Else starting a new session.
       */
      let existingSessionUsed = false;
      if (this.#session) {
        this.#emit("existingSessionUsedForBundlerConfig");
        existingSessionUsed = true;
      } else bundlerConnection.isActive && (await this.#client.startSession());

      /**
       * Configure Metro Bundler URL.
       */
      bundlerConnection.isActive &&
        (await this.#configureBundlerUrl(
          bundlerUrl,
          bundlerConnectionId,
          existingSessionUsed
        ));

      /**
       * Emitting "connectedToBundler" to allow consumer to execute custom logic after connection to Metro Bundler has been established.
       */
      bundlerConnection.isActive && this.#emit("connectedToBundler");
    } catch (error) {
      /**
       * Emitting "bundlerConfigurationStepsFailed" event only when bundler connection is active and configuration steps failed.
       */
      if (bundlerConnection.isActive && error instanceof BundlerConfigError) {
        this.#emit("bundlerConfigurationStepsFailed");
        return;
      }

      /**
       * Emitting "bundlerConnectionFailed" event only when bundler connection is active.
       */
      bundlerConnection.isActive && this.#emit("bundlerConnectionFailed");
    }
  }

  async cancelBundlerConnection(bundlerConnectionId, shouldEndSession) {
    const bundlerConnection = this.#bundlerConnections.get(bundlerConnectionId);

    if (bundlerConnection) {
      /**
       * Setting the bundler connection ID as inactive so that it does not proceed with the URL configuration steps unnecessarily.
       */
      bundlerConnection.isActive = false;

      /**
       * Ending the session in which bundler is being configured.
       */
      this.#session && shouldEndSession && (await this.#session.end());

      /**
       * Emitting the "bundlerConnectionCancelled" event so that the consumer can execute custom logic after bundler connection is cancelled.
       */
      this.#emit("bundlerConnectionCancelled");
    }
  }

  #emit(eventName, data) {
    const eventHanlder = this.#events.get(eventName);
    eventHanlder && eventHanlder(data);
  }

  async #configureBundlerUrl(
    bundlerUrl,
    bundlerConnectionId,
    existingSessionUsed
  ) {
    const bundlerConnection = this.#bundlerConnections.get(bundlerConnectionId);

    /**
     * Emitting "bundlerUrlConfigStarted" to allow consumer to execute custom logic before Bundler URL configurations start.
     */
    bundlerConnection.isActive && this.#emit("bundlerUrlConfigStarted");

    /**
     * Wait for App UI to load.
     */
    bundlerConnection.isActive && (await this.#session.getUI());

    try {
      /**
       * Start automating the steps to configure Bundler URL.
       */
      bundlerConnection.isActive &&
        (await this.#automateBundlerUrlConfigurationSteps(
          bundlerUrl,
          bundlerConnectionId,
          existingSessionUsed
        ));

      /**
       * Emitting "bundlerUrlConfigured" to allow consumer to execute custom logic after Bundler URL has been configured.
       */
      bundlerConnection.isActive && this.#emit("bundlerUrlConfigured");
    } catch (error) {
      throw new BundlerConfigError(error.message);
    }
  }

  async #automateBundlerUrlConfigurationSteps(
    bundlerUrl,
    bundlerConnectionId,
    existingSessionUsed
  ) {
    const os = this.#client.app.platform;

    /**
     * Automate steps to configure Bundler URL based on current OS.
     */
    os === "android"
      ? await this.#automateBundlerUrlForAndroid(
          bundlerUrl,
          bundlerConnectionId,
          existingSessionUsed
        )
      : await this.#automateBundlerUrlForIos(
          bundlerUrl,
          bundlerConnectionId,
          existingSessionUsed
        );
  }

  async #automateBundlerUrlForAndroid(
    bundlerUrl,
    bundlerConnectionId,
    existingSessionUsed
  ) {
    const bundlerConnection = this.#bundlerConnections.get(bundlerConnectionId);

    // Wait for App UI to load.
    bundlerConnection.isActive &&
      !existingSessionUsed &&
      (await this.#waitForAppUiToLoad(bundlerConnectionId));

    // Open Android Keycode Menu
    bundlerConnection.isActive &&
      (await this.#session.keypress("ANDROID_KEYCODE_MENU"));

    /**
     * Find Change Bundle Location menu item and click on it.
     *
     * Clicking on "Change Bundle Location" on Android Tablet devices clicks on other links.
     * Clicking on "Reload" or "Debug" actually clicks on "Change Bundle Location" link.
     * This is an anomaly at Appetize end.
     * Once this issue is fixed from Appetize remove the if condition.
     */
    if (this.#session.device.type.includes("tab")) {
      if (this.#session.device.orientation === "portrait") {
        bundlerConnection.isActive &&
          (await this.#session.findElement({
            attributes: {
              text: "Reload",
            },
          }));
        bundlerConnection.isActive &&
          (await this.#session.tap({
            element: {
              attributes: {
                text: "Reload",
              },
            },
          }));
      } else {
        bundlerConnection.isActive &&
          (await this.#session.findElement({
            attributes: {
              text: "Debug",
            },
          }));
        bundlerConnection.isActive &&
          (await this.#session.tap({
            element: {
              attributes: {
                text: "Debug",
              },
            },
          }));
      }
    } else {
      bundlerConnection.isActive &&
        (await this.#session.findElement({
          attributes: {
            text: "Change Bundle Location",
          },
        }));
      bundlerConnection.isActive &&
        (await this.#session.tap({
          element: {
            attributes: {
              text: "Change Bundle Location",
            },
          },
        }));
    }

    /**
     * Type in Bundler URL.
     */
    bundlerConnection.isActive &&
      (await this.#session.type(`${bundlerUrl}:80`));

    /**
     * Find and click on OK button.
     *
     * Clicking on "OK" does not work on Tablet devices for Android.
     * This is an anomaly from Appetize end.
     * Once this issue is fixed remove the if condition.
     */
    bundlerConnection.isActive &&
      (await this.#session.findElement({
        attributes: {
          text: "OK",
        },
      }));
    if (this.#session.device.type.includes("tab")) {
      if (this.#session.device.orientation === "portrait")
        bundlerConnection.isActive &&
          (await this.#session.tap({
            position: {
              x: "80%",
              y: "37%",
            },
          }));
      else
        bundlerConnection.isActive &&
          (await this.#session.tap({
            position: {
              x: "75%",
              y: "35%",
            },
          }));
    } else {
      bundlerConnection.isActive &&
        (await this.#session.tap({
          element: {
            attributes: {
              text: "OK",
            },
          },
        }));
    }
  }

  async #automateBundlerUrlForIos(
    bundlerUrl,
    bundlerConnectionId,
    existingSessionUsed
  ) {
    const bundlerConnection = this.#bundlerConnections.get(bundlerConnectionId);

    // Wait for App UI to load.
    bundlerConnection.isActive &&
      !existingSessionUsed &&
      (await this.#waitForAppUiToLoad(bundlerConnectionId));

    // Shake iOS device to open Debug Menu.
    bundlerConnection.isActive && (await this.#session.shake());

    /**
     * Find and click on Configure Bundler menu option.
     *
     * Clicking on "Configure Bundler" does not work for iOS Tablet devices.
     * This is an anomaly at Appetize end.
     * Remove the if condition once this issue is resolved.
     */
    if (this.#session.device.type.includes("ipad")) {
      try {
        bundlerConnection.isActive &&
          (await this.#session.findElement({
            attributes: {
              accessibilityLabel: "Switch to normal mode",
            },
          }));
        bundlerConnection.isActive &&
          (await this.#session.tap({
            element: {
              attributes: { accessibilityLabel: "Switch to normal mode" },
            },
          }));

        bundlerConnection.isActive && (await this.#session.waitForAnimations());
      } catch (error) {
        if (
          !(
            error.message.includes("No element found for selector") &&
            error.message.includes("Switch to normal mode")
          )
        )
          throw error;
      }

      bundlerConnection.isActive &&
        (await this.#session.tap({
          position: {
            x: "50%",
            y: "75%",
          },
        }));
    } else {
      bundlerConnection.isActive &&
        (await this.#session.findElement({
          attributes: {
            text: "Configure Bundler",
          },
        }));
      bundlerConnection.isActive &&
        (await this.#session.tap({
          element: {
            attributes: {
              text: "Configure Bundler",
            },
          },
        }));
    }

    /**
     * Find, click and type in the URL input.
     */
    bundlerConnection.isActive &&
      (await this.#session.findElement({
        attributes: {
          text: "0.0.0.0",
        },
      }));
    bundlerConnection.isActive &&
      (await this.#session.tap({
        element: {
          attributes: {
            text: "0.0.0.0",
          },
        },
      }));
    bundlerConnection.isActive && (await this.#session.type(bundlerUrl));

    /**
     * Find, click and type in the Port input.
     *
     * Clicking on "Port Number (8081 Placeholder)" does not work for iOS Tablet devices.
     * This is an anomaly at Appetize end.
     * Remove the if condition once this issue is resolved.
     */
    bundlerConnection.isActive &&
      (await this.#session.findElement({
        attributes: {
          placeholder: "8081",
        },
      }));
    if (this.#session.device.type.includes("ipad")) {
      bundlerConnection.isActive &&
        (await this.#session.tap({
          position: {
            x: "50%",
            y: "47%",
          },
        }));
    } else {
      bundlerConnection.isActive &&
        (await this.#session.tap({
          element: {
            attributes: {
              placeholder: "8081",
            },
          },
        }));
    }
    bundlerConnection.isActive && (await this.#session.type("80"));

    /**
     * Find and click on Apply Changes button.
     *
     * Clicking on "Apply Changes" does not work for iOS Tablet devices.
     * This is an anomaly at Appetize end.
     * Remove the if condition once this issue is resolved.
     */
    bundlerConnection.isActive &&
      (await this.#session.findElement({
        attributes: {
          text: "Apply Changes",
        },
      }));
    if (this.#session.device.type.includes("ipad")) {
      bundlerConnection.isActive &&
        (await this.#session.tap({
          position: {
            x: "50%",
            y: "59%",
          },
        }));
    } else {
      bundlerConnection.isActive &&
        (await this.#session.tap({
          element: {
            attributes: {
              text: "Apply Changes",
            },
          },
        }));
    }
  }

  async #waitForAppUiToLoad(bundlerConnectionId) {
    const bundlerConnection = this.#bundlerConnections.get(bundlerConnectionId);

    // Check if App UI has loaded.
    bundlerConnection.isActive && (await this.#session.waitForAnimations());
    bundlerConnection.isActive && (await this.#session.getUI());
  }
}
