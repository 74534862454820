import React from "react";
import builderLoadingAnimation from "../../../assets/images/builder-loading-animation.gif";

export const Loader = ({
  isLoading,
  loaderTitle,
  shouldBlurBackground,
  extraClasses,
}) => {
  return (
    <>
      {isLoading && (
        <div
          className={`flex absolute top-0 left-0 bottom-0 right-0 z-30 ${
            shouldBlurBackground && " backdrop-blur-3xl"
          }`}
        >
          <div
            className={`w-full h-full flex flex-col justify-start items-center ${
              extraClasses && extraClasses.join(" ")
            }`}
          >
            <img
              src={builderLoadingAnimation}
              alt={loaderTitle}
              className="max-w-[160px] max-h-[160px]"
            />
            <p className="-mt-5 text-sm text-parameterTitleColour font-medium tracking-wide">
              {loaderTitle}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
