import { appetize } from "./appetize-initial-state";
import { emulatorOptions } from "./emulator-options-initial-state";
import { ide } from "./ide-initial-state";
import { ui } from "./ui-initial-state";

/**
 * Represents the App/Global state when application loads for the first time.
 */
export const initialState = {
  ui,
  emulatorOptions,
  appetize,
  ide,

  /**
   * Add more state slices here. For example - IDE details, user preferences, etc.
   */
};
