import React from "react";

export const Popup = ({ title, content, onClose }) => {
  return (
    <div className="absolute top-0 left-0 bottom-0 right-0 z-50 bg-popUpBackground dark:bg-popUpBackgroundDark flex justify-center items-center">
      <div className="w-1/2 h-max bg-white dark:bg-backgroundPrimaryDark rounded-md shadow-lg">
        <div className="flex justify-between items-center px-8 py-6">
          <h1 className="text-black dark:text-primaryDark text-2xl font-medium">
            {title || ""}
          </h1>
          <button
            className="text-3xl font-extralight text-parameterTitleColour dark:text-secondaryDark cursor-pointer hover:scale-105 transition-transform duration-300 ease-linear"
            onClick={() => onClose()}
          >
            &#10005;
          </button>
        </div>

        <div className="px-8 py-6 border-t border-b border-solid border-ideLogoBottomBorder dark:border-borderDark">
          {content || ""}
        </div>

        <div className="flex justify-end items-center px-8 py-6">
          <button
            className=" min-w-[100px] px-3 py-2 shadow-md text-sm font-semibold text-white dark:text-backgroundPrimaryDark bg-ideLogoLight dark:bg-ideLogoDark rounded"
            onClick={onClose}
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};
