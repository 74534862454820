import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../../hooks";

export const SearchLogs = ({
  logsByDuplicacy,
  highlightedLogElements,
  onQueryChange,
  onActiveHighlightedLogChange,
}) => {
  const [ui] = useAppContext((state) => state.ui);
  const { selectedTab } = ui;

  const isSearchDisabled =
    !logsByDuplicacy ||
    (selectedTab === "debug-tab" && !logsByDuplicacy.debugLogs.length) ||
    (selectedTab === "metro-bundler-tab" &&
      !logsByDuplicacy.metroBundlerLogs.length);

  const [query, setQuery] = useState("");
  const [activeHighlightedLog, setActiveHighlightedLog] = useState(null);

  const searchInputRef = useRef(null);
  const debounceTimerRef = useRef(null);

  useEffect(() => {
    highlightedLogElements.length
      ? activateHighlightedLog()
      : setActiveHighlightedLog(null);
  }, [highlightedLogElements]);

  useEffect(() => {
    onActiveHighlightedLogChange(activeHighlightedLog);

    if (activeHighlightedLog) {
      activeHighlightedLog.classList.add("bg-red-300");
      activeHighlightedLog.classList.add("dark:bg-red-300");
      !isHighlightedLogInView(activeHighlightedLog) &&
        activeHighlightedLog.scrollIntoView();

      const previousActiveHighlightedLog = highlightedLogElements.find(
        (highlightedLogElement, index) =>
          highlightedLogElement.classList.contains("bg-red-300") &&
          index !== getActiveHighlightedLogIndex(),
      );
      previousActiveHighlightedLog &&
        removeActiveClasses(previousActiveHighlightedLog);
    }
  }, [activeHighlightedLog]);

  const activateHighlightedLog = () => {
    const currentVisibleElement = highlightedLogElements.find(
      (highlightedLogElement) => isHighlightedLogInView(highlightedLogElement),
    );

    const activeHighlightedLogElement =
      currentVisibleElement || highlightedLogElements[0];

    setActiveHighlightedLog(activeHighlightedLogElement);
  };

  const onMatchTraverseButtonClick = ({ target }) => {
    const activeHighlightedLogIndex = getActiveHighlightedLogIndex();
    const shouldTraverseUp = target.id === "previous-match-button";

    if (
      (activeHighlightedLogIndex > 0 &&
        activeHighlightedLogIndex < highlightedLogElements.length - 1) ||
      (activeHighlightedLogIndex === 0 &&
        highlightedLogElements.length > 1 &&
        !shouldTraverseUp) ||
      (activeHighlightedLogIndex === highlightedLogElements.length - 1 &&
        highlightedLogElements.length > 1 &&
        shouldTraverseUp)
    ) {
      removeActiveClasses(activeHighlightedLog);

      const nextActiveHighligtedLogIndex = shouldTraverseUp
        ? activeHighlightedLogIndex - 1
        : activeHighlightedLogIndex + 1;

      setActiveHighlightedLog(
        highlightedLogElements[nextActiveHighligtedLogIndex],
      );
    }
  };

  const isHighlightedLogInView = (highlightedLog) => {
    const logsContainerRect = document
      .querySelector("div.logs div.tab-content")
      .getBoundingClientRect();

    const highlightedLogRect = highlightedLog.getBoundingClientRect();

    const isHighlightedLogVisible =
      highlightedLogRect.top >= logsContainerRect.top &&
      highlightedLogRect.left >= logsContainerRect.left &&
      highlightedLogRect.bottom <= logsContainerRect.bottom &&
      highlightedLogRect.right <= logsContainerRect.right;

    return isHighlightedLogVisible;
  };

  const removeActiveClasses = (activeHighlightedLog) => {
    activeHighlightedLog.classList.remove("bg-red-300");
    activeHighlightedLog.classList.remove("dark:bg-red-300");
  };

  const getActiveHighlightedLogIndex = () =>
    highlightedLogElements.findIndex(
      (highlightedLogElement) => highlightedLogElement === activeHighlightedLog,
    );

  const onSearchChange = ({ target }) => {
    setQuery(target.value);

    if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current);

    debounceTimerRef.current = setTimeout(() => {
      onQueryChange(searchInputRef.current.value);

      clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = null;
    }, 600);
  };

  return (
    <div
      className={`w-full h-[38px] mt-6 flex items-center box-content border border-solid border-dropdownBorder dark:border-borderDark rounded-[3px] overflow-hidden`}
    >
      <input
        type="text"
        placeholder="Search Logs"
        value={query}
        disabled={isSearchDisabled}
        ref={searchInputRef}
        className="w-4/5 h-full px-3 text-sm text-dropdownOption dark:text-secondaryDark disabled:text-inactiveTextColour disabled:dark:text-disabledDark placeholder:text-dropdownOption placeholder:dark:text-secondaryDark placeholder:disabled:text-inactiveTextColour placeholder:disabled:dark:text-disabledDark bg-transparent disabled:bg-toggleDisabledBackground disabled:dark:bg-backgroundSecondaryDark"
        onChange={onSearchChange}
      />

      <div className="w-1/5 h-full flex">
        <button
          id="previous-match-button"
          title="Previous Match"
          disabled={isSearchDisabled && !highlightedLogElements.length}
          className="w-1/2 text-xl text-dropdownOption dark:text-secondaryDark disabled:text-inactiveTextColour disabled:dark:text-disabledDark bg-transparent disabled:bg-toggleDisabledBackground disabled:dark:bg-backgroundSecondaryDark border-l border-solid border-dropdownBorder dark:border-borderDark"
          onClick={onMatchTraverseButtonClick}
        >
          &#8593;
        </button>
        <button
          id="next-match-button"
          title="Next Match"
          disabled={isSearchDisabled && !highlightedLogElements.length}
          className="w-1/2 text-xl text-dropdownOption dark:text-secondaryDark disabled:text-inactiveTextColour disabled:dark:text-disabledDark bg-transparent disabled:bg-toggleDisabledBackground disabled:dark:bg-backgroundSecondaryDark border-l border-solid border-dropdownBorder dark:border-borderDark"
          onClick={onMatchTraverseButtonClick}
        >
          &#8595;
        </button>
      </div>
    </div>
  );
};
