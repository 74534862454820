import { useContext } from "react";
import { appContext } from "../global-state";

export const useAppContext = (...selectors) => {
  const { state, dispatch } = useContext(appContext);

  const slices = selectors.map((selector) => selector(state) || null);

  return [...slices, dispatch];
};
