import { faro } from "@grafana/faro-web-sdk";
import React from "react";
import { setRotation } from "../../../../../../global-state";
import { useAppContext } from "../../../../../../hooks";
import { OptionLabel } from "../OptionLabel";

export const OrientationOption = () => {
  const [appetize, dispatch] = useAppContext((state) => state.appetize);
  const { session, isRotating, isConfiguringBundler } = appetize;

  const onRotateButtonClick = async (rotationDirection) => {
    if (session) {
      dispatch(
        setRotation({
          direction: rotationDirection,
          degrees: 90,
        })
      );

      faro.api.pushEvent(`click_rotate_${rotationDirection}`);
    }
  };

  const getRotateButton = (isRotateLeftButton) => {
    return (
      <button
        id={`rotate-${isRotateLeftButton ? "left" : "right"}`}
        className={`flex-grow ${
          isRotateLeftButton &&
          "border-r border-solid border-dropdownBorder dark:border-borderDark"
        }`}
        disabled={!session || isRotating || isConfiguringBundler}
        onClick={() =>
          onRotateButtonClick(isRotateLeftButton ? "left" : "right")
        }
      >
        {isRotateLeftButton ? <span>&#8634;</span> : <span>&#8635;</span>}
      </button>
    );
  };

  return (
    <div>
      <OptionLabel id="option-orientation" title="orientation" />
      <div
        id="option-orientation"
        className={`w-max min-w-[94px] min-h-[38px] flex overflow-hidden rounded-[3px] border border-solid border-dropdownBorder dark:border-borderDark ${
          session && !isRotating && !isConfiguringBundler
            ? "text-dropdownOption dark:text-secondaryDark bg-white dark:bg-backgroundPrimaryDark"
            : "text-inactiveTextColour dark:text-disabledDark bg-toggleDisabledBackground dark:bg-backgroundSecondaryDark"
        }`}
      >
        {getRotateButton(true)}
        {getRotateButton(false)}
      </div>
    </div>
  );
};
