import React, { useLayoutEffect, useState } from "react";
import { setOs } from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import { Loader } from "../../../shared";
import {
  AppetizeClient,
  AppetizeError,
  AutomatedStepsFailureModal,
} from "./components";
import "./appetize.scss";

export const Appetize = () => {
  const [emulatorOptions, ide, appetize, dispatch] = useAppContext(
    (state) => state.emulatorOptions,
    (state) => state.ide,
    (state) => state.appetize
  );
  const { os } = emulatorOptions.selected;
  const { androidPublicKey, iosPublicKey } = ide;
  const { isClientLoading, isConfiguringBundler } = appetize;

  const [appErrorMessage, setAppErrorMessage] = useState(null);

  useLayoutEffect(() => {
    appErrorMessage && setAppErrorMessage(null);
  }, [os]);

  useLayoutEffect(() => {
    (androidPublicKey || iosPublicKey) &&
      !os &&
      dispatch(
        setOs((iosPublicKey && "ios") || (androidPublicKey && "android"))
      );
  }, [androidPublicKey, iosPublicKey]);

  return (
    <div className="appetize-container px-6 pb-4 pt-0 flex-grow flex flex-col justify-start items-center overflow-hidden relative">
      <Loader
        isLoading={isClientLoading || isConfiguringBundler}
        loaderTitle={`${
          (isClientLoading && "Loading Appetize client..") ||
          (isConfiguringBundler && "Connecting to Metro Bundler..")
        }`}
        shouldBlurBackground={isConfiguringBundler}
        extraClasses={isClientLoading ? ["pt-12"] : ["pt-36"]}
      />

      <AppetizeError
        isLoadingClient={isClientLoading}
        appErrorMessage={appErrorMessage}
      />

      {(androidPublicKey || iosPublicKey) && os && (
        <AppetizeClient
          isAndroidClient={os === "android"}
          onError={setAppErrorMessage}
          key={`appetize-client-${os}`}
        />
      )}

      <AutomatedStepsFailureModal />
    </div>
  );
};
