import React from "react";
import { Toast } from "../../../../../shared";
import { useAppContext } from "../../../../../../hooks";
import { NO_PUBLIC_KEY_ERROR } from "../../../../../../global-state";

export const AppetizeError = ({ isLoadingClient, appErrorMessage }) => {
  const [ide] = useAppContext((state) => state.ide);
  const { androidPublicKey, iosPublicKey } = ide;

  return (
    <>
      {!androidPublicKey && !iosPublicKey && (
        <Toast message={NO_PUBLIC_KEY_ERROR} isError={true} />
      )}
      {!isLoadingClient && appErrorMessage && (
        <Toast message={appErrorMessage} isError={true} />
      )}
    </>
  );
};
