/**
 * An initial/default state slice.
 * Used to store all available emulator options like - device, OS, OS versions, etc.
 * Also used to determine what options are currently selected by the user.
 */
export const emulatorOptions = {
  available: {
    appVersions: ["latest"],
    devices: {
      android: {},
      ios: {},
    },
  },
  isLoading: false,
  error: "",
  selected: {
    appVersion: "latest",
    os: null,
    osVersion: null,
    device: null,
    scale: "auto",
  },
  rotation: null,
  isStaticMode: true,
};
