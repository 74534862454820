import React from "react";
import { Dropdown } from "../../../../../shared";
import { setScale } from "../../../../../../global-state";
import { useAppContext } from "../../../../../../hooks";

export const Scale = () => {
  const [scale, isClientReady, isConfiguringBundler, dispatch] = useAppContext(
    (state) => state.emulatorOptions.selected.scale,
    (state) => state.appetize.isClientReady,
    (state) => state.appetize.isConfiguringBundler,
  );

  const onScaleChange = ({ value }) => dispatch(setScale(value));

  const scaleOptions = [
    { label: "25%", value: 25 },
    { label: "50%", value: 50 },
    { label: "75%", value: 75 },
    { label: "100%", value: 100 },
    { label: "Auto", value: "auto" },
  ];
  const selectedScaleOption = scaleOptions.find(({ value }) => value === scale);

  return (
    <Dropdown
      id="scale-select-dropdown"
      title="scale"
      options={scaleOptions}
      selectedOption={selectedScaleOption}
      onChange={onScaleChange}
      isDisabled={!isClientReady || isConfiguringBundler}
    />
  );
};
