import React from "react";
import { Dropdown } from "../../../../../shared";
import { OptionLabel } from "../OptionLabel";

/**
 * Renders an Emulator option.
 * Renders label and select dropdown for an option.
 */
export const Option = ({
  id,
  title,
  options,
  selectedOption,
  onChange,
  isDisabled,
}) => {
  return (
    <div className="mb-4">
      <OptionLabel id={id} title={title} />

      <Dropdown
        id={id}
        title={title}
        options={options}
        selectedOption={selectedOption}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    </div>
  );
};
