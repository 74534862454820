import React, { memo, useEffect, useLayoutEffect, useRef } from "react";
import { setSelectedTab } from "../../../../global-state";
import { useAppContext } from "../../../../hooks";
import { Tabs } from "../../../shared";
import { Log } from "./Log";
import "./logs.scss";

const LogsContainer = ({
  logsByDuplicacy,
  query,
  onLogHighlight,
  onUnmatchedQuery,
}) => {
  const [appetize, emulatorOptions, ui, dispatch] = useAppContext(
    (state) => state.appetize,
    (state) => state.emulatorOptions,
    (state) => state.ui,
  );
  const {
    isClientReady,
    appetizeLogs,
    isAndroidDebugEnabled,
    isIosDebugEnabled,
  } = appetize;
  const { selected } = emulatorOptions;
  const { os } = selected;
  const { selectedTab } = ui;

  let hasQueryMatched = false;

  const latestDebugLogRef = useRef(0);
  const latestMetroBundlerLogRef = useRef(0);

  useEffect(() => {
    latestDebugLogRef.current =
      logsByDuplicacy && logsByDuplicacy.debugLogs.length
        ? logsByDuplicacy.debugLogs.length - 1
        : 0;
    latestMetroBundlerLogRef.current =
      logsByDuplicacy && logsByDuplicacy.metroBundlerLogs.length
        ? logsByDuplicacy.metroBundlerLogs.length - 1
        : 0;
  }, [appetizeLogs]);

  useLayoutEffect(() => {
    const scrollElement = document.getElementsByClassName(
      `scrollElement-${
        selectedTab === "debug-tab"
          ? latestDebugLogRef.current + 1
          : latestMetroBundlerLogRef.current + 1
      }`,
    )[0];
    scrollElement && scrollElement.scrollIntoView(true);
  }, [logsByDuplicacy]);

  useEffect(() => {
    !hasQueryMatched && onUnmatchedQuery();
  }, [logsByDuplicacy, query]);

  let tabsData = [
    {
      id: "debug-tab",
      title: "Debug",
      content:
        (isClientReady &&
          ((os === "android" && !isAndroidDebugEnabled) ||
            (os === "ios" && !isIosDebugEnabled)) && (
            <Log
              logId="debugging-not-enabled"
              appearanceCount={0}
              log="Debug logs are disabled for this app."
            />
          )) ||
        (selectedTab === "debug-tab" &&
          logsByDuplicacy &&
          logsByDuplicacy.debugLogs.map(
            ({ logTimestamp, appearanceCount, log }, index) => {
              if (!hasQueryMatched && query && log.includes(query))
                hasQueryMatched = true;

              return (
                <Log
                  key={`${logTimestamp}-${index}`}
                  logId={`${logTimestamp}-${index}`}
                  appearanceCount={appearanceCount}
                  log={log}
                  query={query}
                  scrollIntoViewClass={
                    index === latestDebugLogRef.current + 1 &&
                    `scrollElement-${latestDebugLogRef.current + 1}`
                  }
                  onLogHighlight={onLogHighlight}
                />
              );
            },
          )),
    },

    /**
     * Metro Bundler and Other tabs are not required in V1.
     * Commenting them out for now.
     */
    // {
    //   id: "metro-bundler-tab",
    //   title: "Metro Bundler",
    //   content:
    //     (isClientReady &&
    //       ((os === "android" && !isAndroidDebugEnabled) ||
    //         (os === "ios" && !isIosDebugEnabled)) && (
    //         <Log
    //           logId="debugging-not-enabled"
    //           appearanceCount={0}
    //           log="Debug logs are disabled for this app."
    //         />
    //       )) ||
    //     (selectedTab === "metro-bundler-tab" &&
    //       logsByDuplicacy &&
    //       logsByDuplicacy.metroBundlerLogs.map(
    //         ({ logTimestamp, appearanceCount, log }, index) => (
    //           <Log
    //             key={`${logTimestamp}-${index}`}
    //             logId={`${logTimestamp}-${index}`}
    //             appearanceCount={appearanceCount}
    //             log={log}
    //             query={query}
    //             scrollIntoViewClass={
    //               index === latestMetroBundlerLogRef.current + 1 &&
    //               `scrollElement-${latestMetroBundlerLogRef.current + 1}`
    //             }
    //             onLogHighlight={onLogHighlight}
    //           />
    //         )
    //       )),
    // },
  ];

  const onTabChange = (selectedTabId) => {
    dispatch(setSelectedTab(selectedTabId));
  };

  return (
    <div className="logs w-full overflow-hidden">
      <Tabs
        tabsData={tabsData}
        selectedTabId={selectedTab}
        onChange={onTabChange}
      />
    </div>
  );
};

export const Logs = memo(LogsContainer);
